import { Outlet } from 'react-router-dom'
import { Breakpoints, useWindowSize } from '@urbanx/agx-ui-components'
import SideBar from 'components/sidebar/Sidebar'
import { useState } from 'react'
import MobileHeader from 'components/mobileHeader/MobileHeader'
import { ContentScrollContext } from 'contexts'

const MainLayout = () => {
  const screenSize = useWindowSize()
  const isMobile = screenSize === Breakpoints.Mobile
  const isTabletPortrait = screenSize === Breakpoints.Tablet_Portrait
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true)
  const [contentScrollPosition, setContentScrollPosition] = useState(0)

  return (
    <div className="container">
      <SideBar
        isMenuCollapsed={isMenuCollapsed}
        setIsMenuCollapsed={setIsMenuCollapsed}
      />
      {isTabletPortrait && (
        <div
          className="backdrop"
          onClick={() => setIsMenuCollapsed(true)}
        ></div>
      )}
      <ContentScrollContext.Provider
        value={{ contentScrollPosition, setContentScrollPosition }}
      >
        <div
          className="main-content"
          id="main-content"
          onScroll={(e: React.UIEvent<HTMLDivElement>) => {
            const newScrollPosition = e.currentTarget.scrollTop
            setContentScrollPosition(newScrollPosition)
          }}
        >
          {(isMobile || isTabletPortrait) && (
            <MobileHeader
              isMenuCollapsed={isMenuCollapsed}
              setIsMenuCollapsed={setIsMenuCollapsed}
            />
          )}
          <Outlet />
        </div>
      </ContentScrollContext.Provider>
    </div>
  )
}

export default MainLayout
