import React from 'react'
import {
  AgxLabel,
  AgxDivider,
  UploadedDocumentInfo,
} from '@urbanx/agx-ui-components'
import DocumentRow from './DocumentRow'

interface Props {
  documents: UploadedDocumentInfo[]
  getDocumentTypeLabel: (documentType: string) => string
}
const DocumentSection = (props: Props) => {
  const { documents, getDocumentTypeLabel } = props

  return (
    <>
      {documents.map((document, innerIndex) => (
        <React.Fragment key={document.documentId}>
          <React.Fragment>
            {innerIndex > 0 && <AgxDivider />}
            <AgxLabel medium>
              {document.title ?? getDocumentTypeLabel(document.documentType)}
            </AgxLabel>
          </React.Fragment>
          <DocumentRow document={document} />
        </React.Fragment>
      ))}
    </>
  )
}

export default DocumentSection
