import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'

interface UpdateListingAgreementSettingsRequest extends ApiRequestBody {
  privacyPolicyLink?: string
  AgencyId?: string
}

const UpdateListingAgreementSettings = async (
  requestBody: UpdateListingAgreementSettingsRequest
): Promise<number | undefined> => {
  const { getAuthToken, AgencyId, ...requestProperties } = requestBody

  if (!AgencyId) return

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.put(
    `UpdateListingAgreementSettings?AgencyId=${AgencyId}`,
    { ...requestProperties }
  )

  return result.status
}

export default UpdateListingAgreementSettings
