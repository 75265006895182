import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import PSPDFKit, { AnnotationsUnion, Instance, List } from 'pspdfkit'
import {
  EditablePdfToolbarItem,
  getCurrentCenterPosition,
  getWidgets,
  setAnnotationBadge,
} from './PdfHelpers'
import './PdfViewerComponent.scss'
import { EditablePdfButton, EditablePdfButtonGroup } from './types'

interface Props {
  documentUrl: string
  buttonGroups: EditablePdfButtonGroup[]
  getLicenseKey: () => Promise<string | undefined>
  onFieldsUpdated: (fields: string[]) => void
  onSavePdf: (blob: Blob) => Promise<void>
  onClose: () => void
}

const PdfViewerComponent = (props: Props) => {
  const {
    documentUrl,
    buttonGroups,
    getLicenseKey,
    onFieldsUpdated,
    onSavePdf,
    onClose,
  } = props
  const containerRef = useRef(null)
  const instanceRef = useRef<Instance | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [widgetsAdded, setWidgetsAdded] = useState<string[]>([])

  useEffect(() => {
    onFieldsUpdated(widgetsAdded)
  }, [widgetsAdded])

  const onPdfButtonClick = useCallback(
    (button: EditablePdfButton) => {
      if (!instanceRef.current) return

      const viewportCenter = getCurrentCenterPosition(instanceRef.current)
      const widgets = getWidgets(
        button,
        instanceRef.current.viewState.currentPageIndex,
        viewportCenter
      )
      widgets.forEach(({ widget, formField }) => {
        if (!instanceRef.current) return

        instanceRef.current.create([widget, formField])
        instanceRef.current.setViewState((viewState) =>
          viewState.set(
            'interactionMode',
            PSPDFKit.InteractionMode.FORM_CREATOR
          )
        )

        if (widget.name != null && widget.id != null)
          setAnnotationBadge(widget.id, widget.name)
      })
    },
    [PSPDFKit, instanceRef.current]
  )

  const toolbarItems = useMemo<EditablePdfToolbarItem[]>(() => {
    const buttonItems =
      buttonGroups
        ?.map((buttonGroup) => {
          const buttonGroupId = PSPDFKit.generateInstantId()

          const buttons: EditablePdfToolbarItem[] = buttonGroup.buttons.map(
            (button) => ({
              type: 'custom',
              title: button.buttonName,
              id: PSPDFKit.generateInstantId(),
              dropdownGroup: buttonGroupId,
              pdfFieldName: button.pdfFieldName,
              onPress: () => {
                onPdfButtonClick(button)
              },
            })
          )

          return buttons
        })
        .reduce((acc, val) => acc.concat(val), []) || []

    return [
      ...buttonItems,
      { type: 'spacer' },
      {
        type: 'custom',
        id: 'save-pdf',
        title: 'Save',
        onPress: async () => {
          if (instanceRef.current) {
            setIsSaving(true)
            const arrayBuffer = await instanceRef.current.exportPDF()
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' })
            await onSavePdf(blob)
            setIsSaving(false)

            if (containerRef.current) PSPDFKit.unload(containerRef.current)

            onClose()
          }
        },
      },
      {
        type: 'custom',
        className: 'PSPDFKit-Toolbar-Button PSPDFKit-Tool-Button',
        id: 'close-popup',
        title: 'Close',
        onPress: () => {
          if (containerRef.current) PSPDFKit.unload(containerRef.current)

          onClose()
        },
      },
    ]
  }, [instanceRef.current, onPdfButtonClick, buttonGroups])

  useEffect(() => {
    const toolbarItemsUpdated = toolbarItems.map((item) => {
      if (item.pdfFieldName != null && widgetsAdded.includes(item.pdfFieldName))
        return { ...item, disabled: true }
      else return item
    })

    instanceRef.current?.setToolbarItems([...toolbarItemsUpdated])
  }, [widgetsAdded])

  useEffect(() => {
    ;(async function () {
      if (containerRef.current == null) return

      if (containerRef.current) PSPDFKit.unload(containerRef.current)

      const licenseKey =
        window.location.hostname !== 'localhost'
          ? await getLicenseKey()
          : undefined

      if (PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS === false) {
        PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS = true
      }

      instanceRef.current = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container: containerRef.current,
        // The document to open.
        document: documentUrl,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/static/`,

        licenseKey: licenseKey,

        theme: PSPDFKit.Theme.LIGHT,

        styleSheets: ['/static/PdfViewerStyles.css'],

        toolbarItems: toolbarItems,
      })

      instanceRef.current?.addEventListener(
        'annotations.create',
        (annotations: List<AnnotationsUnion>) => {
          const annotationsCustomData = Array.from(annotations.values())
            .filter((a) => a.customData != null)
            .map((a) => a.customData as Record<string, unknown>)

          const annotationsFieldNames = annotationsCustomData
            .filter((d) => d.fieldName != null && d.isPrimary === true)
            .map((d) => d.fieldName as string)

          setWidgetsAdded((existingWidetsAdded) => [
            ...existingWidetsAdded,
            ...annotationsFieldNames,
          ])
        }
      )

      instanceRef.current?.addEventListener(
        'annotations.delete',
        async (annotations: List<AnnotationsUnion>) => {
          const annotationsData = Array.from(annotations.values())

          const annotationsCustomData = annotationsData
            .filter((a) => a.customData != null)
            .map((a) => a.customData as Record<string, unknown>)

          const baseFormFieldNames = annotationsCustomData
            .filter((d) => d.baseFormFieldName != null)
            .map((d) => d.baseFormFieldName as string)

          const currentFields = await instanceRef.current?.getFormFields()

          if (currentFields != null) {
            const fieldsToRemove = Array.from(currentFields.values())?.filter(
              (field) => baseFormFieldNames.includes(field.name)
            )

            if (fieldsToRemove.length > 0) {
              await instanceRef.current?.delete(fieldsToRemove)
            }
          }

          const annotationsFieldNames = annotationsCustomData
            .filter((d) => d.fieldName != null && d.isPrimary === true)
            .map((d) => d.fieldName as string)

          setWidgetsAdded((existingWidetsAdded) =>
            existingWidetsAdded.filter(
              (w) => !annotationsFieldNames.includes(w)
            )
          )
        }
      )

      for (let i = 0; i < instanceRef.current.totalPageCount; i++) {
        const existingAnnotations = await instanceRef.current.getAnnotations(i)

        if (existingAnnotations != null && existingAnnotations.count() > 0) {
          const annotations = Array.from(existingAnnotations.values())

          annotations.forEach((a) => {
            if (a.name != null && a.id != null) setAnnotationBadge(a.id, a.name)
          })

          const annotationsCustomData = annotations
            .filter((a) => a.customData != null)
            .map((a) => a.customData as Record<string, unknown>)

          const annotationsFieldNames = annotationsCustomData
            .filter((d) => d.fieldName != null && d.isPrimary === true)
            .map((d) => d.fieldName as string)

          setWidgetsAdded((existingWidetsAdded) => [
            ...existingWidetsAdded,
            ...annotationsFieldNames,
          ])

          instanceRef.current.setViewState((viewState) =>
            viewState.set(
              'interactionMode',
              PSPDFKit.InteractionMode.FORM_CREATOR
            )
          )
        }
      }
    })()

    return () => {
      if (containerRef.current) PSPDFKit.unload(containerRef.current)
    }
  }, [])

  return (
    <>
      <div className="edit-pdf-container" ref={containerRef} />
      {isSaving && (
        <div className="PDF-Saving">
          <p>Saving...</p>
        </div>
      )}
    </>
  )
}

export default PdfViewerComponent
