import { ContractDetailModel } from '@urbanx/agx-ui-components'
import CampaignsService from 'services/campaignsService'

const getContractById = async (
  contractId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<ContractDetailModel | undefined> => {
  if (!contractId || !getAuthToken) return
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  const result = await service.campaignsService.get('StaffGetContract', {
    ContractId: contractId,
  })

  return result.data
}

export default getContractById
