interface Props {
  height?: string
  width?: string
  padding?: string
  color?: string
  opacity?: number
}

const AutoPilotIcon = (props: Props) => {
  const {
    height = '50',
    width = '52',
    color = '#FFFFFF',
    padding = '0',
    opacity = 1,
  } = props

  return (
    <div style={{ padding: `${padding}`, opacity }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 52 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.4681 35.98H22.5905C22.5905 35.98 22.5874 35.979 22.5863 35.9774L18.1557 28.4278C18.1536 28.4247 18.1557 28.4205 18.1599 28.4205H28.0851C31.302 28.4205 34.0128 25.7882 33.9824 22.5713C33.9688 21.1156 33.4177 19.7887 32.5199 18.7787C31.4581 17.5849 29.9117 16.8327 28.1883 16.8327C26.1689 16.8327 24.3909 17.8662 23.3537 19.4314C23.2877 19.5331 23.2233 19.6362 23.1625 19.7421L18.1515 28.42L13.7874 35.9795H5L6.54585 33.3027L9.3641 28.42L20.8446 8.53662C22.7974 5.1542 27.6791 5.15472 29.632 8.53662L29.6382 8.5471L34.0317 16.158L41.1124 28.42L45.4723 35.9722C45.4744 35.9753 45.4723 35.9795 45.4681 35.9795V35.98Z"
          fill={color}
        />
      </svg>
    </div>
  )
}
export default AutoPilotIcon
