import { Configuration } from '@azure/msal-browser'

const AUTH = {
  CLIENT_ID: import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID,
  SIGNIN_SIGNUP_POLICY_ID: import.meta.env.VITE_REACT_APP_AUTH_SIGNIN_SIGNUP_POLICY_ID,
  INSTANCE: import.meta.env.VITE_REACT_APP_AUTH_INSTANCE,
  AUTHORITY_DOMAIN: import.meta.env.VITE_REACT_APP_AUTH_AUTHORITY_DOMAIN,
  DOMAIN: import.meta.env.VITE_REACT_APP_AUTH_DOMAIN,
}
const { AUTHORITY_DOMAIN, INSTANCE, DOMAIN, SIGNIN_SIGNUP_POLICY_ID } = AUTH

const b2cPolicies = {
  names: {
    signUpSignIn: SIGNIN_SIGNUP_POLICY_ID,
  },
  authorities: {
    signUpSignIn: {
      authority: `${INSTANCE}/${DOMAIN}/${SIGNIN_SIGNUP_POLICY_ID}`,
    },
  },
  authorityDomain: AUTHORITY_DOMAIN,
}

export const authConfig = () => {
  const config: Configuration = {
    auth: {
      clientId: AUTH.CLIENT_ID ?? '',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain ?? ''],
      redirectUri: import.meta.env.VITE_REACT_APP_ENV_SIGNIN_PREFIX,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  }
  
  return config;
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid'],
}
