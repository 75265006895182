import { QueryKey } from '@tanstack/react-query'
import MarketingService from 'services/marketingService'
import { MarketingPackageTemplate } from 'types/marketing'

const StaffGetMarketingPackageTemplates = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<MarketingPackageTemplate[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const agencyId = queryKey[1] as string
  const service = new MarketingService(token)

  if (!service) return

  const result = await service.marketingService.get('StaffGetMarketingPackageTemplates', { agencyId })

  return result.data || []
}

export default StaffGetMarketingPackageTemplates
