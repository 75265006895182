import { AustralianState, FormType } from '@urbanx/agx-ui-components'
import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import CampaignsService from 'services/campaignsService'

interface FormSubmission extends ApiRequestBody {
  CampaignId: string
  FormId?: string
  FormType: FormType
  State: AustralianState
  BlankContractIsLive: boolean
  FormData: object
  NotifyAgent: boolean
}

export interface FormSubmissionResponse {
  errors: string[]
  blankContractIsLive: boolean;
}

const submitForm = async (
  requestBody: FormSubmission
): Promise<FormSubmissionResponse | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const result = await service.campaignsService.post('StaffSubmitForm', {
    ...requestProperties,
  })

  return result.data as FormSubmissionResponse
}

export default submitForm
