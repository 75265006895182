import { createContext, useContext } from "react";

export const ContentScrollContext = createContext<{
  contentScrollPosition: number;
  setContentScrollPosition: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);

export const useContentScroll = () => {
  const context = useContext(ContentScrollContext);
  if (!context) {
    throw new Error("useContentScroll must be used within ContentScrollContext.Provider");
  }
  return context;
};