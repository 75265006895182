import { ReactElement } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  AgxColumn,
  AustralianState,
  Breakpoints,
  useWindowSize,
} from '@urbanx/agx-ui-components'
import slugify from 'slugify'
import {
  Menu,
  MenuTitles,
  StaffMenu,
  BlankContractMenu,
  ContractsMenu,
} from 'constants/menu'
import { SideMenu } from 'types/commonTypes'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useSelectedAgency } from 'hooks'
import './ContentLayout.scss'

export interface ContentLayoutProps {
  children: ReactElement | ReactElement[]
  isStaff?: boolean
  isCampaigns?: boolean
  isContracts?: boolean
  secondaryActiveMenu?: MenuTitles
  tertiaryActiveMenu?: MenuTitles
  hasSecondaryMenu?: boolean
  tertiaryMenu?: SideMenu[]
}

const ContentLayout = (props: ContentLayoutProps) => {
  const {
    hasSecondaryMenu,
    tertiaryMenu,
    secondaryActiveMenu,
    tertiaryActiveMenu,
    isStaff,
    isCampaigns,
    isContracts,
    children,
  } = props
  const { agencyId, campaignId, contractId } = useParams()
  const [, , userHasRequiredRoles] = useAzureAuth()
  const { agency: selectedAgency, isLoading, isFetched } = useSelectedAgency()
  const navigate = useNavigate()
  const windowSize = useWindowSize()

  const generateLink = (pageLink: string) => {
    if (pageLink === '') return '/'

    if (agencyId) {
      if (contractId) {
        return `/${agencyId}/campaigns/${campaignId}/contracts/${contractId}/${pageLink}`
      } else if (campaignId) {
        return `/${agencyId}/campaigns/${campaignId}/${pageLink}`
      }

      return `/${agencyId}/${pageLink}`
    }
    return pageLink || '#'
  }

  if (agencyId && (!isFetched || isLoading)) return null

  const agencyStates =
    selectedAgency?.companyDetails?.licences
      ?.filter((l) => !!l?.state)
      .map((l) => l.state as number) ?? []

  const getTertiaryMenuContainerStyle = () => {
    if (windowSize === Breakpoints.Desktop && tertiaryActiveMenu)
      return 'tertiaryMenuContainer'
    if (windowSize !== Breakpoints.Desktop && !tertiaryActiveMenu)
      return 'tertiaryMenuContainerNoActiveMenu'

    return 'hideContent'
  }

  const renderSideMenu = (
    menuItems: SideMenu[],
    activeMenu?: MenuTitles,
    isTertiary: boolean = false
  ) => {
    const selectedStates = agencyStates.map(
      (index) => Object.values(AustralianState)[index]
    )

    const tertiaryMenuItemStyle = tertiaryActiveMenu
      ? 'tertiaryMenuItem'
      : 'tertiaryMenuItemNoActiveMenu'

    return menuItems
      .filter((menu) => userHasRequiredRoles(menu.requiredRoles))
      .filter(
        (menu) =>
          menu.includeOnlyInState.length === 0 ||
          menu.includeOnlyInState.some((state) =>
            selectedStates.includes(state)
          )
      )
      .map((menu, index) => (
        <li
          onClick={() => {
            navigate(generateLink(menu.pageLink ?? ''))
          }}
          key={`sideMenu_${index}`}
          className={`${isTertiary ? tertiaryMenuItemStyle : 'secondaryMenuItem'} ${
            activeMenu && activeMenu === menu.title ? 'active' : ''
          }`}
        >
          <Link
            data-testid={`link-${slugify(menu.title)}`}
            to={generateLink(menu.pageLink ?? '')}
          >
            {menu.title}
          </Link>
        </li>
      ))
  }

  return (
    <div
      className={
        hasSecondaryMenu
          ? 'contentWithSecondaryMenu'
          : 'contentWithoutSecondaryMenu'
      }
    >
      {windowSize === Breakpoints.Desktop && hasSecondaryMenu && (
        <ul className="secondaryMenuContainer">
          {isStaff && renderSideMenu(StaffMenu, secondaryActiveMenu)}
          {isCampaigns &&
            renderSideMenu(BlankContractMenu, secondaryActiveMenu)}
          {isContracts && renderSideMenu(ContractsMenu, secondaryActiveMenu)}
          {!isStaff &&
            !isCampaigns &&
            !isContracts &&
            renderSideMenu(Menu, secondaryActiveMenu)}
        </ul>
      )}

      <AgxColumn fill largeGap>
        {windowSize !== Breakpoints.Desktop && hasSecondaryMenu && (
          <ul className="secondaryMenuContainer">
            {isStaff && renderSideMenu(StaffMenu, secondaryActiveMenu)}
            {isCampaigns &&
              renderSideMenu(BlankContractMenu, secondaryActiveMenu)}
            {isContracts && renderSideMenu(ContractsMenu, secondaryActiveMenu)}
            {!isStaff &&
              !isCampaigns &&
              !isContracts &&
              renderSideMenu(Menu, secondaryActiveMenu)}
          </ul>
        )}

        <div
          className={
            tertiaryMenu
              ? 'contentWithTertiaryMenu contentContainer'
              : 'contentWithoutTertiaryMenu'
          }
        >
          {tertiaryMenu && (
            <ul className={getTertiaryMenuContainerStyle()}>
              {renderSideMenu(tertiaryMenu, tertiaryActiveMenu, true)}
            </ul>
          )}

          {!(tertiaryMenu && !tertiaryActiveMenu) && (
            <div
              className={
                tertiaryMenu
                  ? 'contentContainerWithTertiaryMenu'
                  : 'contentContainer'
              }
            >
              {children}
            </div>
          )}
        </div>
      </AgxColumn>
    </div>
  )
}

export default ContentLayout
