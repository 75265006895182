import { AppFeatures } from '@urbanx/agx-ui-components'
import AgenciesService from 'services/agenciesService'

const GetFeatureToggles = async (
  campaignId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<AppFeatures | undefined> => {
  if (!campaignId || !getAuthToken) return

  const token = await getAuthToken()

  if (!token) return

  const service = new AgenciesService(token)
  if (!service) return

  const { data: featureToggles } = await service.agencyService.get(
    'StaffGetFeatureToggles',
    { campaignId }
  )

  return featureToggles || {}
}

export default GetFeatureToggles
