import CampaignsService from 'services/campaignsService'
import { Contract } from 'types/campaigns'

const getContractsForCampaign = async (
  campaignId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<Contract[] | undefined> => {
  if (!campaignId || !getAuthToken) return
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  const result = await service.campaignsService.get(
    'StaffGetContractsForCampaign',
    {
      CampaignId: campaignId,
    }
  )

  return result.data
}

export default getContractsForCampaign
