import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import MarketingService from 'services/marketingService'

interface CreateMarketingPackageTemplateRequest extends ApiRequestBody {
  AgencyId: string
  Order: number
  Name: string
  DisplayName: string
  IncludeRealEstateListing: boolean
  IncludeDomainListing: boolean
  DisplayItemisedPricingOnPdf: boolean
  Items: string[]
  AddOns: string[]
  assignedAgentIds: string[]
  specialConditions?: string
}

const CreateMarketingPackageTemplate = async (
  requestBody: CreateMarketingPackageTemplateRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new MarketingService(token)

  if (!service) return

  const result = await service.marketingService.post('CreateMarketingPackageTemplate', {
    ...requestProperties,
  })

  return result.status
}

export default CreateMarketingPackageTemplate
