import { StaffApi } from 'helpers'

class MarketingService {
  authToken: string
  marketingService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.marketingService = new StaffApi('marketing', authToken)
  }
}

export default MarketingService
