import CampaignsService from 'services/campaignsService'

const StaffGetUploadedFileLink = async (
  filePath: string,
  getAuthToken: () => Promise<string | void>
): Promise<string | undefined> => {
  if (!filePath) return

  const token = await getAuthToken()

  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const result = await service.campaignsService.get(
    'StaffGetUploadedFileLink',
    {
      filePath,
    }
  )

  return result.data
}

export default StaffGetUploadedFileLink
