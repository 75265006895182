import { JSX } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { AgxRow, Images } from '@urbanx/agx-ui-components'
import './SortableItem.scss'

const SortableItem = ({
  id,
  children,
  extraClasses,
}: {
  id: string
  children: JSX.Element
  extraClasses?: string
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const sortableItemStyles = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      style={sortableItemStyles}
      className="borderBottomContainer"
    >
      <AgxRow centered veryLargeGap extraClasses={extraClasses}>
        <div {...attributes} {...listeners} style={{ cursor: 'grab' }}>
          <div>
            <Images.DragDropIcon />
          </div>
        </div>
        <div style={{ width: '100%' }}>{children}</div>
      </AgxRow>
    </div>
  )
}

export default SortableItem
