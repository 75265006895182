import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import { UploadedDocument } from 'types/agency'
import AgencyService from 'services/agenciesService'

interface UpdateBrandingRequest extends ApiRequestBody {
  selectedFile: File
  AgencyId?: string
  getAuthToken: () => Promise<string | void>
}

const UploadPublicDocument = async (
  requestBody: UpdateBrandingRequest
): Promise<UploadedDocument | undefined> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const { AgencyId, selectedFile } = requestBody

  if (!service || !AgencyId) return

  const formdata = new FormData()
  formdata.append('File', selectedFile)

  const result = await service.agencyService.post(
    `UploadPublicDocument?AgencyId=${AgencyId}`,
    formdata,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return result.data as UploadedDocument
}

export default UploadPublicDocument
