import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  AgxButton,
  AgxColumn,
  AgxLabel,
  AgxRow,
  AgxTextInput,
  AgxToast,
  useWindowSize,
  Breakpoints,
  Images,
} from '@urbanx/agx-ui-components'
import { FormPrompt } from 'components/FormPrompt'
import { MenuTitles, SettingsMenu } from 'constants/menu'
import agencyContext from 'contexts/agencyContext'
import userContext from 'contexts/userContext'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { ContentLayout, PageLayout } from 'layout'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  GetAllAgencies,
  GetListingAgreementSettings,
  UpdateListingAgreementSettings,
} from 'services'
import { Agency } from 'types/agency'
import { ListingAgreementSettings } from 'types/agencySettings'
import { AgxToastState } from 'types/commonTypes'

const ListingAgreementView = () => {
  const user = useContext(userContext)
  const [selectedAgency, setSelectedAgency] = useContext(agencyContext)
  const { agencyId } = useParams()
  const windowSize = useWindowSize()
  const [, getAuthToken] = useAzureAuth()
  const [formLoaded, setFormLoaded] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const queryClient = useQueryClient()
  const [tertiaryActiveMenu, setTertiaryActiveMenu] = useState<
    MenuTitles | undefined
  >(MenuTitles.LISTING_AGREEMENT)
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const [listingAgreementSettings, setListingAgreementSettings] =
    useState<ListingAgreementSettings>({
      privacyPolicyLink: '',
    })

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const {
    data: agencyListingAgreementSettings,
    isFetched,
    isLoading,
  } = useQuery<ListingAgreementSettings | undefined>({
    queryKey: ['getListingAgreementSettings', agencyId],
    queryFn: (ctx) =>
      GetListingAgreementSettings(ctx.queryKey[1] as string, getAuthToken),
    enabled: !!agencyId,
  })

  const { mutate: updateListingAgreementSettings } = useMutation({
    mutationFn: UpdateListingAgreementSettings,
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({
        queryKey: ['getListingAgreementSettings', agencyId],
      })
      updateToastState({
        color: 'success',
        message: 'Listing agreement saved successfully',
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error saving listing agreement',
        open: true,
      })
    },
  })

  const saveChanges = () => {
    updateListingAgreementSettings({
      getAuthToken,
      ...listingAgreementSettings,
      AgencyId: agencyId || '',
    })
  }

  useEffect(() => {
    if (agencyId && selectedAgency?.id !== agencyId) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies, agencyId])

  useEffect(() => {
    if (isFetched && agencyListingAgreementSettings) {
      setListingAgreementSettings(agencyListingAgreementSettings)
      setIsFormDirty(false)

      setFormLoaded(true)
    }
  }, [agencyListingAgreementSettings])

  if (!isFetched || isLoading || !formLoaded) return null

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={MenuTitles.SETTINGS}
    >
      <ContentLayout
        hasSecondaryMenu={true}
        secondaryActiveMenu={MenuTitles.SETTINGS}
        tertiaryMenu={SettingsMenu}
        tertiaryActiveMenu={tertiaryActiveMenu}
      >
        <FormPrompt hasUnsavedChanges={isFormDirty} />
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn veryLargeGap>
          <AgxRow
            spaceBetween
            centered
            extraClasses={`borderBottomContainer ${windowSize === Breakpoints.Desktop ? 'pageTitleContainerWithTertiaryMenu' : ''}`}
          >
            {windowSize === Breakpoints.Desktop && (
              <AgxLabel large>{MenuTitles.SETTINGS}</AgxLabel>
            )}
            {windowSize !== Breakpoints.Desktop && (
              <AgxButton
                id="btn-back"
                leftIcon={<Images.ArrowBackOutline />}
                naked
                large
                text={MenuTitles.LISTING_AGREEMENT}
                onClick={() => setTertiaryActiveMenu(undefined)}
              />
            )}

            <AgxButton
              text="Save Changes"
              medium
              primary
              onClick={saveChanges}
            />
          </AgxRow>
          <AgxColumn veryLargeGap extraClasses="container100Percent">
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Listing Agreement</AgxLabel>
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="privacyPolicyLink"
                label="Privacy Policy Link"
                stretch
                noOptionalLabel
                defaultValue={listingAgreementSettings.privacyPolicyLink || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setListingAgreementSettings((previousState) => ({
                    ...previousState,
                    privacyPolicyLink: value,
                  }))
                }
              />
            </AgxRow>
          </AgxColumn>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default ListingAgreementView
