import { StaffApi } from 'helpers'

class CampaignFlowService {
  authToken: string
  campaignFlowService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.campaignFlowService = new StaffApi('campaignflow', authToken)
  }
}

export default CampaignFlowService
