import { AgxColumn, AgxHeader, AgxRow } from '@urbanx/agx-ui-components'
import './panel.scss'
import { JSX } from 'react'

interface PanelProps {
  children: JSX.Element
  title?: string
  actionContent?: JSX.Element
  halfWidth?: boolean
}

const Panel = ({ children, title, actionContent, halfWidth }: PanelProps) => {
  let classes = 'panel'
  if (halfWidth) {
    classes += ' container50Percent'
  }

  return (
    <AgxColumn extraLargeGap alignStart extraClasses={classes}>
      {(title || actionContent) && (
        <AgxRow fill centered extraClasses="panel-headerRow">
          {title && <AgxHeader size={5}>{title}</AgxHeader>}
          {actionContent && actionContent}
        </AgxRow>
      )}
      {children}
    </AgxColumn>
  )
}

export default Panel
