import AgencyService from 'services/agenciesService'
import { Agent } from 'types/agency'

const getAgentsInAgency = async (
  agencyId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<Agent[] | undefined> => {
  if (!agencyId || !getAuthToken) return

  const token = await getAuthToken()

  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get('StaffGetAgentsInAgency', {
    agencyId: agencyId,
  })

  return result.data
}

export default getAgentsInAgency
