import React from 'react'
import './network.scss'
import { AgxSelect } from '@urbanx/agx-ui-components'
import { ContractDateToUse } from 'types/rex'

const filters = [
  { value: ContractDateToUse.Written.toString(), label: 'Written' },
  {
    value: ContractDateToUse.Unconditional.toString(),
    label: 'Unconditional',
  },
  { value: ContractDateToUse.Settled.toString(), label: 'Settled' },
]

interface FilterDropdownProps {
  selectedFilter: ContractDateToUse
  setSelectedFilter: (filter: ContractDateToUse) => void
}

const FilterDropdown = ({
  selectedFilter,
  setSelectedFilter,
}: FilterDropdownProps) => {
  return (
    <AgxSelect
      id={'yearMonthDropdown'}
      options={filters}
      onValueChanged={({ value }) => {
        let selectedValue = ContractDateToUse.Written

        switch (value) {
          case ContractDateToUse.Written.toString():
            selectedValue = ContractDateToUse.Written
            break
          case ContractDateToUse.Unconditional.toString():
            selectedValue = ContractDateToUse.Unconditional
            break
          case ContractDateToUse.Settled.toString():
            selectedValue = ContractDateToUse.Settled
            break
        }
        setSelectedFilter(selectedValue)
      }}
      defaultValue={
        filters.find((m) => m.value === selectedFilter.toString()) ?? filters[0]
      }
      required
      extraClasses={'reporting-year-month-dropdown'}
    />
  )
}

export default FilterDropdown
