import { UploadedDocument } from '@urbanx/agx-ui-components'

export enum ConnectionState {
  NotConnected,
  Connecting,
  Connected,
  Reconnecting,
  Disconnected,
}

export enum NotificationType {
  PdfProcessedEvent = 'PdfProcessedEvent',
  ReadyForDocuSignEvent = 'ReadyForDocuSignEvent',
  CampaignUpdatedEvent = 'CampaignUpdatedEvent',
}

export interface Notification {
  connectionState: ConnectionState
  connectionUpdatedAt: Date
  registerEvent: (
    eventType: string,
    callback: (event: NotificationEvents) => void
  ) => void
  unregisterEvent: (
    eventType: string,
    eventCallback: (event: NotificationEvents) => void
  ) => void
}

export interface PdfProcessedEvent {
  campaignId: string
  formId: string
  uploadedDocument: UploadedDocument
}

export interface CampaignUpdatedEvent {
  campaignId: string
}

export type NotificationEvents = PdfProcessedEvent & CampaignUpdatedEvent
