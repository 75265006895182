import { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { AgencyContext } from 'contexts'
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core'
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable'
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxTextInput,
  AgxSelect,
  AgxHeader,
  AgxCaption,
  AgxCurrency,
  Images,
  AgxToast,
} from '@urbanx/agx-ui-components'
import { v4 as uuidv4 } from 'uuid'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SetMarketingItemTemplates } from 'services'
import './editItems.scss'
import DeleteConfirmation from 'components/delete-confirmation/DeleteConfirmation'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import { AgxToastState } from 'types/commonTypes'
import { MarketingItemTemplate, PackageItemType } from 'types/marketing'
import SortableItem from 'components/sortable-item/SortableItem'

interface EditItemsProps {
  onBack: () => void
  existingItems: MarketingItemTemplate[]
  agencyId: string
}
type PackageItemKeys = keyof MarketingItemTemplate

const itemTypeOptions = Object.entries(PackageItemType).map(([key, value]) => ({
  value: key, 
  label: value
}));

const EditItems = ({ onBack, existingItems, agencyId }: EditItemsProps) => {
  const queryClient = useQueryClient()
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [forceCheck, setForceCheck] = useState(false)
  const [selectedAgency] = useContext(AgencyContext)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<
    MarketingItemTemplate | undefined
  >(undefined)
  const [items, setItems] = useState<MarketingItemTemplate[]>([])
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const { mutate: updateItems, data: updateResponse } = useMutation({
    mutationFn: SetMarketingItemTemplates,
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({
        queryKey: [`packageItem-${agencyId}`, agencyId],
      })
      updateToastState({
        color: 'success',
        message: 'Marketing package(s) updated successfully',
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error updating Marketing package(s)',
        open: true,
      })
    },
  })

  const [errorList, setErrorList] = useState<string[]>([])

  const [, getAuthToken] = useAzureAuth()

  useEffect(() => {
    if (existingItems) {
      setItems([...existingItems])
    }
  }, [existingItems])

  useEffect(() => {
    if (updateResponse === 204) {
      queryClient.invalidateQueries({
        queryKey: [`packageItem-${selectedAgency?.id}`, selectedAgency?.id],
      })
      onBack()
    }
  }, [updateResponse])

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      setItems((prev) => {
        const oldIndex = prev.findIndex((item) => item.id === active.id)
        const newIndex = prev.findIndex((item) => item.id === over?.id)
        return arrayMove(prev, oldIndex, newIndex)
      })
    }
  }

  const onSaveHandler = () => {
    const errors = validateForm()
    if (errors.length === 0) {
      setErrorList([])
      updateItems({
        agencyId: selectedAgency?.id || '',
        marketingItemTemplates: sanatizeItems(),
        getAuthToken,
      })
    } else {
      setErrorList(errors)
    }
  }

  const itemObjectUpdateHandler = (
    item: MarketingItemTemplate,
    value: string,
    prop: PackageItemKeys
  ) => {
    let defaultValue = ''
    const convertedValue = value === null || value === undefined ? '' : value
    if (prop === 'pricePerUnit') {
      defaultValue =
        item[prop] === null || item[prop] === undefined
          ? ''
          : parseFloat(`${item[prop]}`).toFixed(2).toString()
    } else {
      defaultValue =
        item[prop] === null || item[prop] === undefined
          ? ''
          : (item[prop] as string).toString()
    }

    if (defaultValue !== convertedValue) {
      setIsFormDirty(true)
    }

    setItems(
      items.map((i) => {
        if (i.id === item.id) {
          return { ...i, [prop]: value }
        }

        return i
      })
    )
  }

  const sanatizeItems = () => {
    const sanatizedItems = items.map((item, index) => {
      // The AgxSelect onValueChanged() function returns a string, so we need to convert it to a number
      if (item.itemType !== PackageItemType.Price) {
        return {
          ...item,
          agencyId: agencyId,
          pricePerUnit: undefined,
          order: index + 1,
        }
      }

      return { ...item, agencyId, order: index + 1 }
    })

    return sanatizedItems
  }

  const validateForm = (): string[] => {
    const errors: string[] = []

    const itemPrices: Record<string, number | undefined> = {}

    items.forEach((item) => {
      // Check for empty product names
      if (item.productName.trim() === '') {
        errors.push('Product name cannot be empty')
      }

      // Get the trimmed product name and price
      const productName = item.productName.trim()
      const price = item.pricePerUnit

      // Check for duplicate product names with the same price
      if (productName in itemPrices) {
        const storedPrice = itemPrices[productName]
        if (storedPrice === price) {
          errors.push(`Duplicate entry for product name ${productName}`)
        }
      } else {
        itemPrices[productName] = price
      }
    })

    return errors
  }

  const onDeleteItemHandler = (item: MarketingItemTemplate) => {
    setSelectedItemToDelete(item)
    setShowDeleteConfirmation(true)
  }

  const onConfirmDelete = () => {
    if (selectedItemToDelete) {
      setIsFormDirty(true)
      setItems(items.filter((i) => i.id !== selectedItemToDelete.id))
      setShowDeleteConfirmation(false)
    }
  }

  const marketingPackageHeaderClasses = clsx(
    'borderBottomContainer',
    'marketingPackageHeader'
  )
  const marketingPackageItemClasses = clsx(
    'dragDropItem',
    'marketingPackageItem'
  )

  const renderItems = (item: MarketingItemTemplate, index: number) => {
    return (
      <SortableItem
        key={item.id || `dragItem-${index}`}
        id={item.id || `dragItem-${index}`}
        extraClasses={marketingPackageItemClasses}
      >
        <AgxRow centered spaceBetween veryLargeGap>
          <AgxRow centered veryLargeGap fill>
            <AgxTextInput
              id={`txtProductName-${item.id}`}
              label=""
              noOptionalLabel
              stretch
              // Autofocus the top newly added item name field
              autoFocus={items[0].productName === ''}
              defaultValue={item.productName}
              onInputValueChange={({ value }: { value: string }) =>
                itemObjectUpdateHandler(item, value, 'productName')
              }
            />
            <AgxTextInput
              id={`txtSupplier-${item.id}`}
              label=""
              noOptionalLabel
              stretch
              defaultValue={item.supplier}
              onInputValueChange={({ value }: { value: string }) =>
                itemObjectUpdateHandler(item, value, 'supplier')
              }
            />

            <AgxTextInput
              id={`txtStockType-${item.id}`}
              label=""
              noOptionalLabel
              stretch
              defaultValue={item.stockType}
              onInputValueChange={({ value }: { value: string }) =>
                itemObjectUpdateHandler(item, value, 'stockType')
              }
            />

            <AgxSelect
              id={`priceType-${item.id}`}
              label=""
              options={itemTypeOptions}
              defaultValue={{
                label: itemTypeOptions.find(option => option.value === item.itemType)?.label,
                value: itemTypeOptions.find(option => option.value === item.itemType)?.value,
              }}
              hideOptionalLabel
              onValueChanged={({ value }: { value: string }) => {
                itemObjectUpdateHandler(item, value, 'itemType')
              }}
            />
            <AgxCurrency
              id={`priceAmount-${item.id}`}
              required
              noHeader
              stretch
              defaultValue={`${item.pricePerUnit}`}
              onInputValueChange={(change) => {
                itemObjectUpdateHandler(
                  item,
                  change.value as string,
                  'pricePerUnit'
                )
              }}
              decimalPoints={2}
              disabled={item.itemType !== PackageItemType.Price}
            />
          </AgxRow>
          <div className="actionContainerTD">
            <AgxButton
              small
              naked
              leftIcon={<Images.CrossFill />}
              onClick={() => onDeleteItemHandler(item)}
            />
          </div>
        </AgxRow>
      </SortableItem>
    )
  }

  const onBackClick = () => {
    if (isFormDirty) setForceCheck(true)
    else onBack()
  }

  return (
    <AgxRow fill>
      <FormPrompt
        hasUnsavedChanges={isFormDirty}
        forceCheck={forceCheck}
        resetForceCheck={(value: boolean) => setForceCheck(value)}
        onBack={onBack}
      />
      <AgxToast selector="#agxToast" toastState={toastState} />
      <AgxColumn fill largeGap>
        {errorList.length > 0 && (
          <AgxColumn fill mediumGap>
            <AgxHeader size={4}>
              Some errors were found while validating the marketing items
            </AgxHeader>
            {errorList.map((error, idx) => (
              <AgxCaption key={idx}>{error}</AgxCaption>
            ))}
          </AgxColumn>
        )}
        <AgxRow veryLargeGap spaceBetween extraClasses="borderBottomContainer">
          <AgxButton
            text="Marketing Package"
            large
            naked
            onClick={() => onBackClick()}
          />
          <AgxButton
            text="Save Changes"
            medium
            primary
            onClick={() => onSaveHandler()}
          />
        </AgxRow>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items
              .map((item) => item.id)
              .filter((id): id is string => id !== undefined)}
            strategy={verticalListSortingStrategy}
          >
            <AgxRow
              centered
              spaceBetween
              veryLargeGap
              extraClasses={marketingPackageHeaderClasses}
            >
              <AgxRow veryLargeGap spaceBetween fill centered>
                <div className="opacity0">
                  <Images.DragDropIcon />
                </div>
                <AgxTextInput
                  id="txtInclusionName"
                  label="Inclusion name"
                  noOptionalLabel
                  stretch
                  extraClasses="hideWrapper"
                />
                <AgxTextInput
                  id="txtSupplierName"
                  label="Supplier name"
                  noOptionalLabel
                  stretch
                  extraClasses="hideWrapper"
                />
                <AgxTextInput
                  id="txtStockType"
                  label="Stock Type"
                  noOptionalLabel
                  stretch
                  extraClasses="hideWrapper"
                />
                <AgxTextInput
                  id="txtPrice"
                  label="Price"
                  noOptionalLabel
                  stretch
                  extraClasses="hideWrapper"
                />
                <AgxTextInput
                  id="txtEmpty"
                  label=""
                  noOptionalLabel
                  stretch
                  extraClasses="hideWrapper"
                />
                <div className="opacity0">
                  <AgxButton text="" small naked />
                </div>
              </AgxRow>
              <div className="addItemButtonContainer">
                <AgxButton
                  text="Add Item"
                  small
                  hollow
                  onClick={() => {
                    setIsFormDirty(true)
                    const newItem: MarketingItemTemplate = {
                      productName: '',
                      itemType: PackageItemType.Price,
                      id: `${uuidv4()}`,
                    }
                    setItems([newItem, ...items])
                  }}
                />
              </div>
            </AgxRow>
            {items && items.map((item, index) => renderItems(item, index))}
          </SortableContext>
        </DndContext>
      </AgxColumn>
      <DeleteConfirmation
        name={selectedItemToDelete?.productName || ''}
        title="Delete marketing item?"
        lineOne="Are you sure you want to delete"
        lineTwo="This cannot be undone and will remove the item from any marketing packages that it has been added to."
        primaryActionTitle="Permanently delete item"
        secondaryActionTitle="No, cancel"
        showPopUp={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => onConfirmDelete()}
      />
    </AgxRow>
  )
}

export default EditItems
