import './editTeamMember.scss'
import DeleteConfirmation from '../../../../components/delete-confirmation/DeleteConfirmation'
import {
  AgxTextInput,
  AgxButton,
  AgxDatePicker,
  AgxColumn,
  AgxRow,
  AgxToast,
  AgxTextArea,
  AgxLabel,
  AgxMultiSelect,
  AgxPhoneNumberInput,
  AgxSelect,
  enumToOptions,
  AustralianStateNumericMap,
  AgxSwitch,
  Option,
} from '@urbanx/agx-ui-components'
import isEqualWith from 'lodash/isEqualWith'
import set from 'lodash/set'
import {
  Team,
  TeamMember,
  UploadedDocument,
  AgentRole,
  LicenceType,
  LicenceDetails,
} from 'types/agency'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PlaceHolderImage } from 'components'
import { useState, useMemo, useEffect, useContext } from 'react'
import {
  CreateAgent,
  DeleteLogo,
  SetAgentDetails,
  UploadPublicDocument,
} from 'services'
import { AgxToastState, NotificationState } from 'types/commonTypes'
import FileUpload from 'components/file-upload/FileUpload'
import { SendInvitationEmail, SoftDeleteAgent } from 'services'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import Panel from 'components/panel/Panel'
import { isDirtyCompare } from 'utils/compares'
import EditRexId from './edit-rex-id/EditRexId'
import { AgencyContext } from 'contexts'
import { ContactType } from 'services/functions/agency/sendInvitationEmail'
import { TeamPageAction } from 'pages/team/types'

interface EditTeamMemberProps {
  showPopUp: boolean
  teamMember: TeamMember
  teams: Team[]
  onBack: () => void
  selectedAgencyId: string
  action: TeamPageAction
}

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`
}[keyof ObjectType & (string | number)]

const AgentRoleOptions = enumToOptions(AgentRole)

const EditTeamMember = (props: EditTeamMemberProps) => {
  const { action, teamMember, onBack, teams, selectedAgencyId } = props
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [emailInviteState, setEmailInviteState] = useState<NotificationState>(
    NotificationState.NotSent
  )
  const queryClient = useQueryClient()
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [forceCheck, setForceCheck] = useState(false)
  const [img, updateImg] = useState(teamMember.photo?.publicPath)
  const [, getAuthToken] = useAzureAuth()
  const [agencyContext] = useContext(AgencyContext)

  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const [teamMemberForm, updateTeamMemberForm] =
    useState<TeamMember>(teamMember)

  const [licenceDetails, setLicenceDetails] = useState<LicenceDetails>(
    (teamMemberForm.agentCompanyDetails.licences &&
      teamMemberForm.agentCompanyDetails.licences[0]) ?? {
      licenceNumber: '',
      expiryDate: '',
    }
  )

  const { mutate: uploadPublicDocument, isPending: isUploading } = useMutation({
    mutationFn: UploadPublicDocument,
    onSuccess: (r) => {
      updateImg(r?.publicPath || '')
      onDetailsChangeHandler(r, 'photo')
    },
  })

  const { mutate: deleteLogo } = useMutation({
    mutationFn: DeleteLogo,
    onSuccess: () => {
      onDetailsChangeHandler(undefined, 'photo')
      queryClient.invalidateQueries({
        queryKey: [`agents-${selectedAgencyId}`, selectedAgencyId],
      })
      updateImg(undefined)
    },
  })

  const { mutate: setAgentDetails } = useMutation({
    mutationFn: SetAgentDetails,
    onSuccess: () => {
      setIsFormDirty(false)
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [`agents-${selectedAgencyId}`, selectedAgencyId],
        }),
        queryClient.invalidateQueries({
          queryKey: [`team-${selectedAgencyId}`, selectedAgencyId],
        }),
      ])
      onBack()
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error saving changes',
        open: true,
      })
    },
  })

  const { mutate: createAgent } = useMutation({
    mutationFn: CreateAgent,
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({
        queryKey: [`agents-${selectedAgencyId}`, selectedAgencyId],
      })
      onBack()
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error creating agent',
        open: true,
      })
    },
  })

  const { mutate: deleteMember } = useMutation({
    mutationFn: SoftDeleteAgent,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`agents-${selectedAgencyId}`, selectedAgencyId],
      })
      onBack()
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error saving changes',
        open: true,
      })
    },
  })

  const onSaveChanges = () => {
    switch (action) {
      case TeamPageAction.EDIT: {
        setAgentDetails({
          getAuthToken,
          ...teamMemberForm,
        })
        break
      }
      case TeamPageAction.CREATE: {
        createAgent({
          getAuthToken,
          ...teamMemberForm,
          agencyId: selectedAgencyId,
        })
        break
      }
    }
  }

  const onDetailsChangeHandler = (
    value:
      | string
      | boolean
      | string[]
      | UploadedDocument
      | undefined
      | AgentRole
      | LicenceDetails[]
      | number,
    prop: NestedKeyOf<TeamMember>
  ) => {
    if (teamMemberForm) {
      const updatedTeamMemberForm = set({ ...teamMemberForm }, prop, value)
      updateTeamMemberForm(updatedTeamMemberForm)
    }
  }

  const uploadLogo = (file: File) => {
    updateImg(URL.createObjectURL(file))
    uploadPublicDocument({
      selectedFile: file,
      AgencyId: selectedAgencyId,
      getAuthToken,
    })
  }

  const { mutate: sendEmailInvite } = useMutation({
    mutationFn: SendInvitationEmail,
    onSuccess: () => {
      setEmailInviteState(NotificationState.Sent)
      queryClient.invalidateQueries({
        queryKey: [`agents-${selectedAgencyId}`, selectedAgencyId],
      })
      updateToastState({
        color: 'success',
        message: 'Email Sent!',
        open: true,
      })
    },
    onError: () => {
      setEmailInviteState(NotificationState.NotSent)
      updateToastState({
        color: 'error',
        message: 'Error sending email invitation',
        open: true,
      })
    },
  })

  const handleEmailSent = () => {
    if (emailInviteState !== NotificationState.Sending) {
      setEmailInviteState(NotificationState.Sending)
      sendEmailInvite({
        id: teamMember.id || '',
        contactType: ContactType.Agent,
        getAuthToken,
      })
    }
  }

  const licenceTypeOptions = [
    {
      state: undefined,
      option: { value: LicenceType.None.toString(), label: 'None' } as Option,
    },
    {
      state: AustralianStateNumericMap.NSW,
      option: {
        value: LicenceType.Class1.toString(),
        label: 'Class 1 Licence',
      } as Option,
    },
    {
      state: AustralianStateNumericMap.NSW,
      option: {
        value: LicenceType.Class2.toString(),
        label: 'Class 2 Licence',
      } as Option,
    },
    {
      state: AustralianStateNumericMap.QLD,
      option: {
        value: LicenceType.RealEstateCertificate.toString(),
        label: 'Real Estate Certificate',
      } as Option,
    },
    {
      state: AustralianStateNumericMap.QLD,
      option: {
        value: LicenceType.RealEstateLicence.toString(),
        label: 'Real Estate Licence',
      } as Option,
    },
  ]

  const canViewLicenseeDetails = () => {
    const { states } = agencyContext || {}
    const { licenceType } = teamMemberForm

    return (
      (states?.includes(AustralianStateNumericMap.NSW) &&
        licenceType === LicenceType.Class1) ||
      (states?.includes(AustralianStateNumericMap.QLD) &&
        licenceType === LicenceType.RealEstateLicence)
    )
  }

  const defaultTeams = teamMemberForm.teams?.reduce(
    (acc: string[], curr: string) => {
      const teamId = teams.find((t) => t.id === curr)?.id
      if (teamId) acc.push(teamId)
      return acc
    },
    []
  )

  const allTeamsOptions = useMemo(() => {
    return teams.map((t) => ({ value: t.id, label: t.name }))
  }, [teams])

  const getLicenceType = (licenceType: LicenceType) =>
    licenceTypeOptions
      .map((x) => x.option)
      .find((x) => x.value === licenceType.toString()) ??
    licenceTypeOptions[0].option

  const onBackClick = () => (isFormDirty ? setForceCheck(true) : onBack())

  useEffect(() => {
    if (!isEqualWith(teamMember, teamMemberForm, isDirtyCompare)) {
      setIsFormDirty(true)
    } else {
      setIsFormDirty(false)
    }
  }, [teamMemberForm])

  return (
    <AgxColumn extraLargeGap>
      <FormPrompt
        hasUnsavedChanges={isFormDirty}
        forceCheck={forceCheck}
        resetForceCheck={(value: boolean) => setForceCheck(value)}
        onBack={onBack}
      />
      <AgxToast selector="#agxToast" toastState={toastState} />
      <AgxRow spaceBetween extraClasses="borderBottomContainer">
        <AgxButton
          text={
            action === TeamPageAction.EDIT
              ? 'Edit Team Member'
              : 'Add Team Member'
          }
          large
          naked
          onClick={() => onBackClick()}
        />
        <AgxRow veryLargeGap>
          <AgxButton
            text="Delete User"
            medium
            danger
            naked
            onClick={() => setShowDeleteConfirmation(true)}
          />
        </AgxRow>
      </AgxRow>

      <Panel
        title="Team member details"
        halfWidth
        actionContent={
          <AgxButton
            text="Save Changes"
            medium
            primary
            disabled={isUploading}
            onClick={() => onSaveChanges()}
          />
        }
      >
        <>
          <AgxRow largeGap extraClasses="flexCentre" fill>
            {!img && (
              <PlaceHolderImage
                title={`${teamMemberForm.firstName} ${teamMemberForm.lastName}`}
                size={60}
              />
            )}
            {img && <img style={{ maxWidth: '150px' }} src={img} />}
            <FileUpload
              id="fileUpload"
              title=""
              wide
              uploadFile={(file) => uploadLogo(file)}
              extensions={['jpg', 'jpeg', 'png', 'svg', 'bmp']}
            />
            <AgxButton
              text="Remove"
              medium
              hollow
              primary
              onClick={() => {
                deleteLogo({
                  FilePath: teamMemberForm.photo?.containerFilePath || '',
                  AgencyId: selectedAgencyId || '',
                  getAuthToken,
                })
              }}
            />
          </AgxRow>
          <AgxRow veryLargeGap fill>
            <AgxTextInput
              id="txtFirstName"
              label="First name"
              noOptionalLabel
              stretch
              defaultValue={teamMemberForm.firstName}
              onInputValueChange={({ value }: { value: string }) =>
                onDetailsChangeHandler(value, 'firstName')
              }
            />
            <AgxTextInput
              id="txtLastName"
              label="Last name"
              noOptionalLabel
              stretch
              defaultValue={teamMemberForm.lastName}
              onInputValueChange={({ value }: { value: string }) =>
                onDetailsChangeHandler(value, 'lastName')
              }
            />
          </AgxRow>
          <AgxRow fill>
            <AgxTextInput
              id="txtJobTitle"
              label="Job Title"
              noOptionalLabel
              stretch
              defaultValue={teamMemberForm.title}
              onInputValueChange={({ value }: { value: string }) =>
                onDetailsChangeHandler(value, 'title')
              }
            />
          </AgxRow>
          <AgxRow fill>
            <AgxTextInput
              id="txtEmail"
              label="Email"
              noOptionalLabel
              disabled={action === TeamPageAction.EDIT ? true : false}
              stretch
              defaultValue={teamMemberForm.email}
              onInputValueChange={({ value }: { value: string }) =>
                onDetailsChangeHandler(value, 'email')
              }
            />
          </AgxRow>
          <AgxRow veryLargeGap fill>
            <AgxPhoneNumberInput
              id="txtPhone"
              label="Phone"
              stretch
              defaultValue={teamMemberForm.phone}
              onInputValueChange={({ value }: { value: string }) =>
                onDetailsChangeHandler(value, 'phone')
              }
            />
            <AgxSelect
              id="selUserRole"
              options={AgentRoleOptions}
              onValueChanged={({ value }: { value: string }) => {
                onDetailsChangeHandler(value, 'role')
              }}
              label="Role"
              defaultValue={
                AgentRoleOptions.find(
                  (upo) => upo.value === teamMemberForm.role
                ) ?? AgentRoleOptions[0]
              }
              required
            />
          </AgxRow>
          <AgxRow fill extraClasses="checkboxContainer">
            <AgxMultiSelect
              id="selTeam"
              label="Team"
              hideOptionalLabel
              defaultValue={defaultTeams}
              onValueChanged={({ value }: { value: string[] }) => {
                onDetailsChangeHandler(value ?? [], 'teams')
              }}
              options={allTeamsOptions}
            />
          </AgxRow>
          <AgxRow fill extraClasses="checkboxContainer">
            <AgxSelect
              id="selLicenseType"
              label="Real Estate Licence"
              hideOptionalLabel
              options={licenceTypeOptions
                .filter(
                  (x) =>
                    (x.state && agencyContext?.states.includes(x.state)) ||
                    !x.state
                )
                .map((x) => x.option)}
              defaultValue={getLicenceType(teamMemberForm.licenceType)}
              onValueChanged={({ value }: { value: string }) => {
                onDetailsChangeHandler(Number(value), 'licenceType')
              }}
            />
          </AgxRow>
          {canViewLicenseeDetails() && (
            <AgxRow spaceBetween centered>
              <AgxSwitch
                id="switchLicenseeDetails"
                width={60}
                dataOn=""
                dataOff=""
                description="Licensee Details"
                isChecked={teamMemberForm.useLicenseeDetails}
                onChange={(isChecked: boolean) => {
                  onDetailsChangeHandler(isChecked, 'useLicenseeDetails')
                }}
              />
              <AgxLabel extraClasses="licenseeDetails">
                Licensee Details
              </AgxLabel>
            </AgxRow>
          )}
          {teamMemberForm.useLicenseeDetails && canViewLicenseeDetails() && (
            <>
              <AgxRow fill>
                <AgxTextInput
                  id="txtLicenseeName"
                  label="Licensee name"
                  noOptionalLabel
                  stretch
                  defaultValue={
                    teamMemberForm.agentCompanyDetails?.licenseeName ?? ''
                  }
                  onInputValueChange={({ value }: { value: string }) =>
                    onDetailsChangeHandler(
                      value,
                      'agentCompanyDetails.licenseeName'
                    )
                  }
                />
              </AgxRow>
              <AgxRow veryLargeGap fill>
                <AgxTextInput
                  id="txtAbn"
                  label="ABN"
                  abn={true}
                  noOptionalLabel
                  stretch
                  defaultValue={teamMemberForm.agentCompanyDetails?.abn ?? ''}
                  onInputValueChange={({ value }: { value: string }) =>
                    onDetailsChangeHandler(value, 'agentCompanyDetails.abn')
                  }
                />
                <AgxTextInput
                  id="txtAcn"
                  label="ACN"
                  acn={true}
                  noOptionalLabel
                  stretch
                  defaultValue={teamMemberForm.agentCompanyDetails?.acn ?? ''}
                  onInputValueChange={({ value }: { value: string }) =>
                    onDetailsChangeHandler(value, 'agentCompanyDetails.acn')
                  }
                />
              </AgxRow>
              <AgxRow veryLargeGap fill>
                <AgxTextInput
                  id="txtlicenceNumber"
                  label="Licence Number"
                  noOptionalLabel
                  stretch
                  defaultValue={licenceDetails.licenceNumber}
                  onInputValueChange={({ value }: { value: string }) => {
                    const updatedLicence: LicenceDetails = {
                      expiryDate: licenceDetails.expiryDate,
                      licenceNumber: value,
                    }
                    setLicenceDetails(updatedLicence)
                    onDetailsChangeHandler(
                      [updatedLicence],
                      'agentCompanyDetails.licences'
                    )
                  }}
                />
                <AgxDatePicker
                  id="dtExipryDate"
                  date
                  label="Expiry Date"
                  noOptionalLabel
                  stretch
                  defaultValue={licenceDetails.expiryDate}
                  onValueChanged={({ value }: { value: string }) => {
                    const updatedLicence: LicenceDetails = {
                      expiryDate: value,
                      licenceNumber: licenceDetails.licenceNumber,
                    }
                    setLicenceDetails(updatedLicence)
                    onDetailsChangeHandler(
                      [updatedLicence],
                      'agentCompanyDetails.licences'
                    )
                  }}
                />
              </AgxRow>
            </>
          )}
          <AgxRow extraClasses="borderBottomContainer" fill>
            <AgxLabel large>Extras</AgxLabel>
          </AgxRow>
          <AgxTextArea
            id="instructionsConditions"
            label="Reassignment Clause"
            parentControlValue
            defaultValue={teamMemberForm.instructionsConditions ?? ''}
            onInputValueChange={({ value }: { value: string }) =>
              onDetailsChangeHandler(value, 'instructionsConditions')
            }
            rows={6}
            maxLength={1100}
            showCharCount
            noOptionalLabel
            stretch
          />
        </>
      </Panel>

      {action === TeamPageAction.EDIT && (
        <>
          <EditRexId
            agentId={teamMember.id}
            updateToastState={updateToastState}
          />
          <AgxRow fill>
            <AgxColumn mediumGap>
              <AgxLabel medium>Email Invitation</AgxLabel>
              <AgxButton
                text={
                  emailInviteState !== NotificationState.Sending
                    ? `${
                        teamMember?.emailInvitationSent ||
                        emailInviteState === NotificationState.Sent
                          ? 'Resend'
                          : 'Send'
                      } Invite`
                    : 'Sending...'
                }
                medium
                primary
                disabled={
                  emailInviteState === NotificationState.Sending ||
                  emailInviteState === NotificationState.Sent
                }
                extraClasses={
                  emailInviteState === NotificationState.Sending
                    ? 'emailInviteButton'
                    : ''
                }
                onClick={handleEmailSent}
              />
            </AgxColumn>
          </AgxRow>
        </>
      )}

      <DeleteConfirmation
        name={`${teamMemberForm.firstName} ${teamMemberForm.lastName}`}
        title="Really delete user?"
        lineOne="Are you sure you want to delete user"
        lineTwo="This cannot be undone."
        primaryActionTitle="Permanently delete user"
        secondaryActionTitle="No, cancel"
        showPopUp={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() =>
          deleteMember({
            getAuthToken,
            AgentId: teamMember.id,
          })
        }
      />
    </AgxColumn>
  )
}

export default EditTeamMember
