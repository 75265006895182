import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import MarketingService from 'services/marketingService'

interface UpdateMarketingPackageRequest extends ApiRequestBody {
  Order: number
  Id: string
  AgencyId: string
  Name: string
  DisplayName: string
  IncludeRealEstateListing: boolean
  IncludeDomainListing: boolean
  DisplayItemisedPricingOnPdf: boolean
  Items: string[]
  AddOns: string[]
  assignedAgentIds: string[]
  specialConditions?: string
}

const UpdateMarketingPackageTemplate = async (
  requestBody: UpdateMarketingPackageRequest
): Promise<void> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new MarketingService(token)

  if (!service) return

  await service.marketingService.put('UpdateMarketingPackageTemplate', {
    ...requestProperties,
  })
}

export default UpdateMarketingPackageTemplate
