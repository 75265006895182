import CampaignsService from 'services/campaignsService'

const InitialiseUser = async (
  getAuthToken?: () => Promise<string | void>
): Promise<void> => {
  if (!getAuthToken) return

  const token = await getAuthToken()

  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  await service.campaignsService.post('InitialiseUser', undefined)
}

export default InitialiseUser
