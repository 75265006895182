import { FormValue, FormValueType } from 'types/formConfig'

export enum ActionType {
  UPDATE = 'UPDATE',
  INIT = 'INIT',
}
type Action = {
  type: ActionType
  key?: string
  payload: FormValueType[keyof FormValueType] | FormValue
}

type BlankContractReducer = FormValue | { initialized: boolean }

const blankContractReducer = (
  state: BlankContractReducer,
  action: Action
): BlankContractReducer => {
  const { type, payload, key } = action
  switch (type) {
    case ActionType.UPDATE: {
      if (key) {
        return { ...state, [key]: payload } as FormValue
      } else {
        return state
      }
    }

    case ActionType.INIT: {
      return { ...state, ...(payload as FormValue), initialized: true }
    }

    default:
      return state
  }
}

export default blankContractReducer
