import { AgxColumn, AgxRow, AgxLabel } from '@urbanx/agx-ui-components'
import DocumentSection from './DocumentSection'
import { DocumentGroup } from 'services/functions/campaigns/getDocumentsForCampaigns'

interface Props {
  documentsInfo: DocumentGroup[]
  getDocumentTypeLabel: (documentType: string) => string
}

const DocumentsList = (props: Props) => {
  const { documentsInfo, getDocumentTypeLabel } = props
  return (
    <>
      {documentsInfo &&
        documentsInfo.map((docGroup) => (
          <AgxColumn
            key={docGroup.title}
            extraClasses="panelContainer files-panel"
          >
            <AgxRow extraClasses="panelTitleContainer">
              <AgxLabel medium extraClasses="left-header">
                {docGroup.title}
              </AgxLabel>
              <AgxLabel medium extraClasses="right-header">
                Date Uploaded
              </AgxLabel>
            </AgxRow>
            {docGroup.documents && (
              <DocumentSection
                documents={docGroup.documents}
                getDocumentTypeLabel={getDocumentTypeLabel}
              />
            )}
          </AgxColumn>
        ))}
    </>
  )
}

export default DocumentsList
