import { UploadedDocumentInfo } from '@urbanx/agx-ui-components'
import CampaignsService from 'services/campaignsService'

export type DocumentGroup = {
  title: string
  documents: UploadedDocumentInfo[]
}

const GetDocumentsForCampaign = async (
  campaignId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<DocumentGroup[] | undefined> => {
  if (!campaignId || !getAuthToken) return

  const token = await getAuthToken()

  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const result = await service.campaignsService.get(
    'StaffGetDocumentsForCampaign',
    { campaignId }
  )

  return result.data
}

export default GetDocumentsForCampaign
