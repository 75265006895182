import React, { JSX, useEffect, useMemo, useState } from 'react'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { NotificationContext } from 'contexts'
import { ConnectionState, NotificationEvents } from 'types/notification'
import {
  getConnection,
  receiveNotifications,
  stopNotifications,
} from 'utils/notifications'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { AppAlert } from 'components/app-alert/app-alert'
import InitialiseUser from 'services/functions/campaigns/initialiseUser'

interface Props {
  children: JSX.Element
}

export const SetupNotificationsLayout = ({
  children,
}: Props) => {
  const [hubConnection, setHubConnection] = useState<HubConnection | null>(null)
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.NotConnected
  )
  const [connectionUpdatedAt, setConnectionUpdatedAt] = useState<Date>(
    new Date()
  )
  const [userAccount, getAuthToken] = useAzureAuth()
  const [hubConnectionStarted, setHubConnectionStarted] =
    useState<boolean>(false)
  const [initialised, setInitialised] = useState<boolean>(false)
  const [lockResolver, setLockResolver] = useState<
    ((value?: unknown) => void) | null
  >(null)

  const notification = useMemo(
    () => ({
      connectionState,
      connectionUpdatedAt,
      registerEvent: (
        eventType: string,
        eventCallback: (event: NotificationEvents) => void
      ) => {
        if (hubConnection)
          receiveNotifications(hubConnection, eventType, eventCallback)
      },
      unregisterEvent: (
        eventType: string,
        eventCallback: (event: NotificationEvents) => void
      ) => {
        if (hubConnection)
          stopNotifications(hubConnection, eventType, eventCallback)
      },
    }),
    [connectionState, connectionUpdatedAt, hubConnection]
  )

  const updateConnectionState = (state: ConnectionState) => {
    setConnectionState(state)
    setConnectionUpdatedAt(new Date())
  }

  // Add a lock to prevent the browser tab from sleeping while it isn't opened
  useEffect(() => {
    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise((res) => {
        setLockResolver(res)
      })

      navigator.locks.request(
        'autopilot_shared_lock',
        {
          mode: 'shared',
        },
        () => {
          return promise
        }
      )
    }

    return () => {
      if (lockResolver) {
        lockResolver()
      }
    }
  }, [])

  // Setup the hub connection
  useEffect(() => {
    if (
      !userAccount ||
      hubConnection ||
      notification?.connectionState === ConnectionState.Connected
    )
      return

    updateConnectionState(ConnectionState.Connecting)

    const hub = getConnection('campaigns', getAuthToken, () => {
      updateConnectionState(ConnectionState.Reconnecting)
    })

    hub.onclose(() => {
      updateConnectionState(ConnectionState.Disconnected)
    })

    hub.onreconnecting(() => {
      updateConnectionState(ConnectionState.Reconnecting)
    })

    hub.onreconnected(() => {
      updateConnectionState(ConnectionState.Connected)
    })

    setHubConnection(hub)
  }, [userAccount])

  // Register the hub connection events
  useEffect(() => {
    if (!hubConnection || !userAccount) return

    if (hubConnection.state === HubConnectionState.Disconnected) {
      hubConnection.start().then(() => {
        updateConnectionState(ConnectionState.Connected)
        setHubConnectionStarted(true)
      })
    }
  }, [hubConnection])

  // Initialise the user to receive staff portal notifications
  useEffect(() => {
    if (
      notification.connectionState === ConnectionState.Connected &&
      !initialised
    ) {
      Promise.all([InitialiseUser(getAuthToken)]).then(() => {
        setInitialised(true)
      })
    }
  }, [notification.connectionState, initialised])

  let appAlert = null

  if (notification.connectionState === ConnectionState.Reconnecting) {
    appAlert = <AppAlert text="Staff Portal is reconnecting..." />
  } else if (notification.connectionState === ConnectionState.Disconnected) {
    appAlert = (
      <AppAlert text="Staff Portal disconnected. Try reload the web page." />
    )
  }

  return (
    <NotificationContext.Provider value={notification}>
      {appAlert}
      {hubConnectionStarted && children}
    </NotificationContext.Provider>
  )
}
