import { useQuery } from '@tanstack/react-query'
import {
  AgxBadge,
  AgxBadgeType,
  AgxBodyText,
  AgxColumn,
  AgxDiagonalUpArrowIcon,
  AgxDivider,
  AgxHeader,
  AgxRow,
  cleanFullAddress,
  formatCurrency,
  Images,
} from '@urbanx/agx-ui-components'
import { MenuTitles } from 'constants/menu'
import { UserContext } from 'contexts'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { ContentLayout, PageLayout } from 'layout'
import { useContext } from 'react'
import getCampaignStatus from 'services/functions/campaignFlow/getCampaignStatus'
import {
  CampaignFlowDisplayStatus,
  CampaignStatusModel,
} from 'types/campaignFlow'
import { useSelectedAgency, useSelectedCampaign } from 'hooks'
import './CampaignMarketing.scss'

const CampaignMarketing = () => {
  const user = useContext(UserContext)
  const [, getAuthToken] = useAzureAuth()
  const { agency } = useSelectedAgency()
  const { campaign } = useSelectedCampaign()

  const campaignFlowStatusKey = ['campaign-flow-status', campaign?.campaignId]

  const {
    data: campaignFlowStatuses,
    isLoading: campaignFlowStatusLoading,
    isFetched: campaignFLowStatusLoaded,
  } = useQuery<CampaignStatusModel[] | undefined>({
    queryKey: campaignFlowStatusKey,
    queryFn: (queryKey) => {
      return getCampaignStatus(queryKey, getAuthToken)
    },
    enabled: !!campaign?.campaignId,
  })

  const mapCampaignFlowStatus = (status?: CampaignFlowDisplayStatus) => {
    if (status === undefined || status === null) return <></>

    switch (status) {
      case CampaignFlowDisplayStatus.AwaitingFunds:
        return <AgxBadge text="Awaiting Funds" type={AgxBadgeType.Waiting} />
      case CampaignFlowDisplayStatus.PayLaterApplicationSigned:
        return (
          <AgxBadge
            text="Pay Later Application Signed"
            type={AgxBadgeType.Action}
          />
        )
      case CampaignFlowDisplayStatus.Paid:
        return <AgxBadge text="Paid" type={AgxBadgeType.Success} />
      case CampaignFlowDisplayStatus.Cancelled:
        return <AgxBadge text="Cancelled" type={AgxBadgeType.Archived} />
      case CampaignFlowDisplayStatus.PendingSettlement:
        return <AgxBadge text="Pending Settlement" type={AgxBadgeType.Action} />
      case CampaignFlowDisplayStatus.Complete:
        return <AgxBadge text="Complete" type={AgxBadgeType.Action} />
      case CampaignFlowDisplayStatus.Withdrawn:
        return <AgxBadge text="Withdrawn" type={AgxBadgeType.Warning} />
      case CampaignFlowDisplayStatus.PayLaterDeclined:
        return (
          <AgxBadge text="Pay Later Declined" type={AgxBadgeType.Warning} />
        )
      default:
        return <></>
    }
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={agency?.name || ''}
      currentPageTitle={`Campaigns / ${cleanFullAddress(campaign?.address)}`}
      isCampaignsSection={true}
    >
      <ContentLayout
        hasSecondaryMenu={true}
        secondaryActiveMenu={MenuTitles.CAMPAIGN_MARKETING}
        isCampaigns={true}
      >
        <>
          {campaignFlowStatusLoading && <div>Loading...</div>}
          {!campaignFlowStatusLoading &&
            campaignFLowStatusLoaded &&
            !(campaignFlowStatuses?.length ?? 0) && (
              <div>No campaign flow information found for campaign</div>
            )}
          {!campaignFlowStatusLoading &&
            campaignFLowStatusLoaded &&
            (campaignFlowStatuses?.length ?? 0) > 0 && (
              <AgxColumn extraClasses="campaignMarketing" veryLargeGap>
                <AgxHeader size={5}>Payments</AgxHeader>
                <AgxDivider />
                <table className="campaignMarketingTable">
                  <tbody>
                    <tr>
                      <th align="left" className="column-name">
                        <AgxBodyText small> Marketing</AgxBodyText>
                      </th>
                      <th align="left" className="column-name">
                        <AgxBodyText small>Flow no.</AgxBodyText>
                      </th>
                      <th align="left" className="column-name">
                        <AgxBodyText small>Status</AgxBodyText>
                      </th>
                      <th align="left" className="column-name">
                        <AgxBodyText small>Total marketing</AgxBodyText>
                      </th>
                      <th align="left" className="column-name">
                        <AgxBodyText small>Vendor to pay</AgxBodyText>
                      </th>
                    </tr>
                    {campaignFlowStatuses &&
                      campaignFlowStatuses.map((campaignFlowStatus) => (
                        <tr
                          key={`marketing-row-${campaignFlowStatus.campaignId}`}
                          className="marketingStatusRow"
                        >
                          <td>
                            <AgxRow mediumGap centered>
                              <Images.SparkleSmall />
                              <AgxHeader size={4}>
                                {
                                  campaignFlowStatus.marketingDetails
                                    ?.marketingPackageName
                                }
                              </AgxHeader>
                            </AgxRow>
                          </td>
                          <td>
                            <AgxRow mediumGap centered>
                              <a
                                href={
                                  campaignFlowStatus.campaignFlowDetails
                                    ?.campaignFlowCampaignUrl
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="campaignIdLink"
                              >
                                <AgxBodyText
                                  small
                                  extraClasses="campaignIdLink"
                                >
                                  {
                                    campaignFlowStatus.campaignFlowDetails
                                      ?.campaignFlowCampaignId
                                  }
                                </AgxBodyText>
                                <AgxDiagonalUpArrowIcon
                                  color="#318CE0"
                                  width={16}
                                  height={16}
                                />
                              </a>
                            </AgxRow>
                          </td>
                          <td>
                            <AgxColumn fill smallGap>
                              {mapCampaignFlowStatus(
                                campaignFlowStatus.campaignFlowDetails
                                  ?.campaignFlowDisplayStatus
                              )}
                              {campaignFlowStatus.campaignFlowDetails
                                ?.campaignFlowStatusInfo && (
                                <AgxBodyText
                                  small
                                  extraClasses="campaignFlowStatusInfo"
                                >
                                  {
                                    campaignFlowStatus.campaignFlowDetails
                                      ?.campaignFlowStatusInfo
                                  }
                                </AgxBodyText>
                              )}
                            </AgxColumn>
                          </td>
                          <td>
                            <AgxBodyText
                              small
                              extraClasses="campaignMarketingAmount"
                            >
                              {formatCurrency(
                                campaignFlowStatus.marketingDetails
                                  ?.totalMarketingAmount ?? null,
                                2
                              )}
                            </AgxBodyText>
                          </td>
                          <td>
                            <AgxBodyText
                              small
                              extraClasses="campaignMarketingAmount"
                            >
                              {formatCurrency(
                                campaignFlowStatus.marketingDetails
                                  ?.amountVendorToPay ?? null,
                                2
                              )}
                            </AgxBodyText>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </AgxColumn>
            )}
        </>
      </ContentLayout>
    </PageLayout>
  )
}

export default CampaignMarketing
