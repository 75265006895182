import { StaffApi } from 'helpers'

class PdfService {
  authToken: string
  pdfService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.pdfService = new StaffApi('pdf', authToken)
  }
}

export default PdfService