import React, { useEffect, useState } from 'react'
import EditablePdfUpload from 'pdf/EditablePdfUpload/EditablePdfUpload'
import {
  AgxLabel,
  AgxColumn,
  AgxCheckboxPanel,
  DocumentTypes,
} from '@urbanx/agx-ui-components'
import { OwnerBuilderDeclarationModel } from './types'
import { EditablePdfButtonGroup } from 'pdf/EditablePdfUpload/components/types'

interface Props {
  id: string
  onChange: (change: {
    id: string
    value: OwnerBuilderDeclarationModel
  }) => void
  defaultValue?: OwnerBuilderDeclarationModel
  getAuthToken: () => Promise<string | void>
  documentType: DocumentTypes
  campaignId: string
  buttonGroups: EditablePdfButtonGroup[]
  uploadFile?: (...args: any) => Promise<any>
  editFile?: (...args: any) => Promise<any>
  deleteFile?: (
    authToken: string,
    filePath: string
  ) => Promise<number | undefined>
  getFileLink?: (authToken: string, filePath: string) => Promise<any>
  getLicenseKey?: (authToken: string) => Promise<string | undefined>
  required: boolean
  requiredPdfFields?: string[]
  validate: boolean
}

const OwnerBuilderDeclaration = ({
  id,
  defaultValue,
  getAuthToken,
  documentType,
  campaignId,
  buttonGroups,
  onChange,
  uploadFile,
  editFile,
  deleteFile,
  getFileLink,
  getLicenseKey,
  required,
  requiredPdfFields,
  validate,
}: Props) => {
  const [checked, setChecked] = useState(defaultValue?.isOwnerBuilder ?? false)
  const [uploadedCertificate, setUploadedCertificate] = useState(
    defaultValue?.ownerBuilderCertificate
  )

  useEffect(() => {
    onChange({
      id,
      value: {
        isOwnerBuilder: checked,
        ownerBuilderCertificate: uploadedCertificate,
      },
    })
  }, [checked, uploadedCertificate])

  return (
    <AgxColumn largeGap>
      <AgxLabel medium>
        Is there an Owner Builder notice on the Title Document?
      </AgxLabel>
      <AgxCheckboxPanel
        id="ownerBuilderDeclarationCheckboxPanel"
        checked={checked}
        onCheckChanged={({ value }) => setChecked(value)}
        checkboxLabel="Yes, an Owner Builder Declaration is required"
        subContent={
          <EditablePdfUpload
            title="Signed Owner Builder Declaration"
            id={`${id}-fileUpload`}
            defaultValue={uploadedCertificate}
            campaignId={campaignId}
            buttonGroups={buttonGroups}
            documentType={documentType}
            getAuthToken={getAuthToken}
            onValueChanged={({ value: uploadedFiles }) =>
              setUploadedCertificate(uploadedFiles)
            }
            uploadFile={uploadFile}
            deleteFile={deleteFile}
            editFile={editFile}
            getFileLink={getFileLink}
            getLicenseKey={getLicenseKey}
            required={checked && required}
            requiredPdfFields={requiredPdfFields}
            validate={validate}
          />
        }
      />
    </AgxColumn>
  )
}

export default OwnerBuilderDeclaration
