import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { AgencyDetails } from 'types/agency'
import { GetAgencyDetails } from 'services'
import { useAzureAuth } from './useAzureAuth'

const useSelectedAgency = (): {
  agency?: AgencyDetails
  isLoading: boolean
  isFetched: boolean
} => {
  const { agencyId } = useParams()
  const [, getAuthToken] = useAzureAuth()
  const agencyQueryKey = ['agency-details', agencyId]

  const {
    data: agency,
    isLoading,
    isFetched,
  } = useQuery<AgencyDetails | undefined>({
    queryKey: agencyQueryKey,
    queryFn: (ctx) => GetAgencyDetails(ctx.queryKey[1] as string, getAuthToken),
    enabled: !!agencyId,
  })

  return {
    agency,
    isLoading,
    isFetched,
  }
}

export default useSelectedAgency
