import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import MarketingService from 'services/marketingService'

interface DeleteMarketingPackageTemplateRequest extends ApiRequestBody {
  AgencyId: string
  MarketingPackageId: string
}

const DeleteMarketingPackageTemplate = async (
  requestBody: DeleteMarketingPackageTemplateRequest
): Promise<number | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new MarketingService(token)

  if (!service) return

  const result = await service.marketingService.post('DeleteMarketingPackageTemplate', {
    ...requestProperties,
  })

  return result.status
}

export default DeleteMarketingPackageTemplate
