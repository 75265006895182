import { useEffect, useRef, useState } from 'react';
import PdfViewerComponent from './PdfViewerComponent';
import './EditPdfDialog.scss';
import { UploadedDocument } from '@urbanx/agx-ui-components';
import { EditablePdfButtonGroup } from './types';

interface Props {
  campaignId: string;
  uploadedDocument?: UploadedDocument;
  buttonGroups: EditablePdfButtonGroup[];
  getAuthToken: () => Promise<string | void>;
  getFileLink?: (authToken: string, filePath: string) => Promise<any>;
  getLicenseKey?: (authToken: string) => Promise<string | undefined>;
  editFile?: (...args: any) => Promise<any>;
  onFieldsUpdated: (fields: string[]) => void;
  onDocumentSaved?: () => void;
  onDialogClosed?: () => void;
}

const EditPdfDialog = (props: Props) => {
  const {
    campaignId,
    uploadedDocument,
    buttonGroups,
    getAuthToken,
    getFileLink,
    getLicenseKey,
    editFile,
    onFieldsUpdated,
    onDocumentSaved,
    onDialogClosed,
  } = props;

  const [downloadedDocument, setDownloadedDocument] = useState<
    string | undefined
  >(undefined);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [pdfIsOpen, setPdfIsOpen] = useState<boolean>(uploadedDocument != null);

  useEffect(() => {
    if (uploadedDocument == null) return;

    (async () => {
      const authToken = await getAuthToken();

      if (authToken && getFileLink) {
        const documentLink = await getFileLink(
          authToken,
          uploadedDocument.containerFilePath,
        );
        setDownloadedDocument(documentLink);
      }
    })();
  }, []);

  useEffect(() => {
    if (pdfIsOpen) {
      document.body.classList.add('hide-scrolling');
      dialogRef.current?.showModal();
    } else {
      document.body.classList.remove('hide-scrolling');
      dialogRef.current?.close();
    }
  }, [pdfIsOpen]);

  if (uploadedDocument == null) return <></>;

  const onSavePdf = async (updatedDocument: Blob): Promise<void> => {
    const authToken = await getAuthToken();

    if (uploadedDocument && editFile && authToken) {
      const formData = new FormData();
      formData.append('file', updatedDocument, uploadedDocument.fileName);
      await editFile(
        authToken,
        formData,
        campaignId,
        uploadedDocument.documentType,
        uploadedDocument?.containerFilePath,
      );

      if (onDocumentSaved != null) onDocumentSaved();
    }
  };

  const onClosePdf = () => {
    dialogRef?.current?.close();
    setPdfIsOpen(false);

    if (onDialogClosed != null) onDialogClosed();
  };

  return (
    <dialog id="edit-pdf-dialog" ref={dialogRef}>
      {downloadedDocument != undefined && (
        <div className="PDF-viewer">
          <PdfViewerComponent
            documentUrl={downloadedDocument}
            buttonGroups={buttonGroups}
            getLicenseKey={async () => {
              const authToken = await getAuthToken();

              if (authToken && getLicenseKey)
                return await getLicenseKey(authToken);
            }}
            onFieldsUpdated={onFieldsUpdated}
            onSavePdf={onSavePdf}
            onClose={onClosePdf}
          />
        </div>
      )}
    </dialog>
  );
};

export default EditPdfDialog;
