import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

// Custom loadash comparison function to recursively compare objects
// and to treat empty strings, undefined, and null as equal so that
// the form is not considered dirty when the user resets a field
export const isDirtyCompare = (
  a: { [index: string]: object } & string & string[],
  b: { [index: string]: object } & string & string[]
) => {
  if (isArray(a) && isArray(b)) {
    const aFiltered = a.filter(
      (item) => item !== null && item !== undefined && item !== ''
    )
    const bFiltered = b.filter(
      (item) => item !== null && item !== undefined && item !== ''
    )
    return (
      aFiltered.length === bFiltered.length &&
      aFiltered.every((val: string | object, index: number) =>
        isEqualWith(val, bFiltered[index], isDirtyCompare)
      )
    )
  } else if (isObject(a) && isObject(b))
    return Object.keys(a).every((key: string) =>
      isEqualWith(a[key], b[key], isDirtyCompare)
    )
  else if (
    (isNull(a) ||
      isUndefined(a) ||
      a === '' ||
      (isArray(a) && a.length === 0)) &&
    (isNull(b) || isUndefined(b) || b === '' || (isArray(b) && b.length === 0))
  )
    return true

  return isEqual(a, b)
}
