import { AgencyCode } from './agencyCode'

export interface CampaignFlow extends AgencyCode {
  code: string
}

export interface CampaignStatusModel {
  campaignId: string
  marketingDetails?: CampaignMarketingDetails
  campaignFlowDetails?: CampaignFlowDetails
}

export interface CampaignMarketingDetails {
  marketingPackageName?: string
  totalMarketingAmount?: number
  amountVendorToPay?: number
}

export interface CampaignFlowDetails {
  campaignFlowCampaignId?: number
  campaignFlowCampaignUrl?: string
  campaignFlowDisplayStatus?: CampaignFlowDisplayStatus
  campaignFlowStatusInfo?: string
}

export enum CampaignFlowDisplayStatus {
  AwaitingFunds,
  PayLaterApplicationSigned,
  Paid,
  Cancelled,
  PendingSettlement,
  Complete,
  Withdrawn,
  PayLaterDeclined,
}
