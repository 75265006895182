import PSPDFKit, {
  CheckBoxFormField,
  Instance,
  Point,
  SignatureFormField,
  TextFormField,
  ToolbarItem,
  WidgetAnnotation,
} from 'pspdfkit'
import { EditablePdfButton, EditablePdfButtonType } from './types'

const PDF_WIDGET_DIMENSIONS = {
  [EditablePdfButtonType.Text]: { width: 180, height: 40 },
  [EditablePdfButtonType.Signature]: { width: 180, height: 40 },
  [EditablePdfButtonType.Checkbox]: { width: 0, height: 0 },
  [EditablePdfButtonType.SignatureAndDate]: { width: 180, height: 40 },
  [EditablePdfButtonType.Initial]: { width: 40, height: 40 },
}

export type EditablePdfToolbarItem = ToolbarItem & {
  pdfFieldName?: string
}

export function getCurrentCenterPosition(instance: Instance): Point {
  const currentZoom = instance.currentZoomLevel
  const viewportEl =
    instance.contentDocument.querySelector('.PSPDFKit-Viewport')

  if (viewportEl == null) throw new Error('Viewport element not found')

  const viewportWidth = viewportEl.clientWidth * currentZoom
  const viewportHeight = viewportEl.clientHeight * currentZoom
  const viewportLeft =
    viewportEl.scrollLeft + (viewportEl.clientWidth - viewportWidth) / 2
  const viewportTop =
    viewportEl.scrollTop + (viewportEl.clientHeight - viewportHeight) / 2

  const centerPosition = instance.transformContentClientToPageSpace(
    new PSPDFKit.Geometry.Point({
      x: viewportLeft + viewportWidth / 2,
      y: viewportTop + viewportHeight / 2,
    }),
    instance.viewState.currentPageIndex
  )

  return centerPosition as Point
}

export function getSignatureWidget(
  pageIndex: number,
  widgetName: string,
  fieldName: string,
  left: number,
  top: number,
  width: number,
  height: number,
  isPrimary = true
): { widget: WidgetAnnotation; formField: SignatureFormField } {
  const widget = new PSPDFKit.Annotations.WidgetAnnotation({
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect({
      left: left,
      top: top,
      width: width,
      height: height,
    }),
    name: widgetName,
    formFieldName: fieldName + 'SignHere',
    customData: {
      fieldName,
      isPrimary,
      baseFormFieldName: fieldName + 'SignHere',
    },
    id: PSPDFKit.generateInstantId(),
  })

  const formField = new PSPDFKit.FormFields.SignatureFormField({
    name: fieldName + 'SignHere',
    label: 'Sign Here',
    annotationIds: PSPDFKit.Immutable.List([widget.id]),
  })

  return { widget, formField }
}

export function getDateSignedWidget(
  pageIndex: number,
  widgetName: string,
  fieldName: string,
  left: number,
  top: number,
  width: number,
  height: number,
  isPrimary = true
): { widget: WidgetAnnotation; formField: TextFormField } {
  const widget = new PSPDFKit.Annotations.WidgetAnnotation({
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect({
      left: left,
      top: top,
      width: width,
      height: height,
    }),
    name: widgetName + ' Date',
    formFieldName: fieldName + 'DateSigned',
    customData: {
      fieldName,
      isPrimary,
      baseFormFieldName: fieldName + 'DateSigned',
    },
    id: PSPDFKit.generateInstantId(),
  })

  const formField = new PSPDFKit.FormFields.TextFormField({
    name: fieldName + 'DateSigned',
    label: 'TextFormField',
    annotationIds: PSPDFKit.Immutable.List([widget.id]),
  })

  return { widget, formField }
}

export function getCheckboxWidget(
  pageIndex: number,
  widgetName: string,
  fieldName: string,
  left: number,
  top: number,
  isPrimary = true
): { widget: WidgetAnnotation; formField: CheckBoxFormField } {
  const widget = new PSPDFKit.Annotations.WidgetAnnotation({
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect({
      left: left,
      top: top,
    }),
    name: widgetName,
    formFieldName: fieldName,
    customData: { fieldName, isPrimary, baseFormFieldName: fieldName },
    id: PSPDFKit.generateInstantId(),
  })

  const formField = new PSPDFKit.FormFields.CheckBoxFormField({
    name: fieldName,
    annotationIds: PSPDFKit.Immutable.List([widget.id]),
  })

  return { widget, formField }
}

export function getTextWidget(
  pageIndex: number,
  widgetName: string,
  fieldName: string,
  left: number,
  top: number,
  width: number,
  height: number,
  isPrimary = true
): { widget: WidgetAnnotation; formField: TextFormField } {
  const widget = new PSPDFKit.Annotations.WidgetAnnotation({
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect({
      left: left,
      top: top,
      width: width,
      height: height,
    }),
    name: widgetName,
    formFieldName: fieldName,
    customData: { fieldName, isPrimary, baseFormFieldName: fieldName },
    id: PSPDFKit.generateInstantId(),
  })

  const formField = new PSPDFKit.FormFields.TextFormField({
    name: fieldName,
    annotationIds: PSPDFKit.Immutable.List([widget.id]),
  })

  return { widget, formField }
}

export function getWidgets(
  button: EditablePdfButton,
  pageIndex: number,
  centerPosition: Point
): {
  widget: WidgetAnnotation
  formField: TextFormField | SignatureFormField | CheckBoxFormField
}[] {
  const { width, height } = PDF_WIDGET_DIMENSIONS[button.pdfFieldType]
  switch (button.pdfFieldType) {
    case EditablePdfButtonType.Text:
      return [
        getTextWidget(
          pageIndex,
          button.buttonName,
          button.pdfFieldName,
          centerPosition.x - width / 2,
          centerPosition.y,
          width,
          height
        ),
      ]
    case EditablePdfButtonType.Signature:
      return [
        getSignatureWidget(
          pageIndex,
          button.buttonName,
          button.pdfFieldName,
          centerPosition.x - width / 2,
          centerPosition.y,
          width,
          height
        ),
      ]
    case EditablePdfButtonType.Checkbox:
      return [
        getCheckboxWidget(
          pageIndex,
          button.buttonName,
          button.pdfFieldName,
          centerPosition.x,
          centerPosition.y
        ),
      ]
    case EditablePdfButtonType.SignatureAndDate:
      return [
        getSignatureWidget(
          pageIndex,
          button.buttonName,
          button.pdfFieldName,
          centerPosition.x - width - 20,
          centerPosition.y,
          width,
          height
        ),
        getDateSignedWidget(
          pageIndex,
          button.buttonName,
          button.pdfFieldName,
          centerPosition.x + 20,
          centerPosition.y,
          width,
          height,
          false
        ),
      ]
    case EditablePdfButtonType.Initial:
      return [
        getTextWidget(
          pageIndex,
          button.buttonName,
          button.pdfFieldName,
          centerPosition.x - width / 2,
          centerPosition.y,
          width,
          height
        ),
      ]
    default:
      throw new Error('Invalid widget type')
  }
}

export function setAnnotationBadge(
  annotationId: string,
  annotationName: string
) {
  if (annotationId == null || annotationName == null) return

  const iFrame = document
    .querySelectorAll(`iframe[title='PSPDFKit']`)
    .item(0) as HTMLIFrameElement
  const frameDocument = iFrame?.contentWindow?.document

  if (frameDocument != null) {
    const el = frameDocument.createElement('style')
    el.innerText = `.PSPDFKit-Annotation[data-annotation-id='${annotationId}']::after {
      content: '${annotationName}';
    }`
    frameDocument.head.appendChild(el)
  }
}
