import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import MarketingService from 'services/marketingService'

interface UpdateMarketingPackageTemplateOrderRequest extends ApiRequestBody {
  marketingPackageIds: string[]
}

const UpdateMarketingPackageTemplateOrder = async (
  requestBody: UpdateMarketingPackageTemplateOrderRequest
): Promise<void> => {
  const { getAuthToken } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new MarketingService(token)

  if (!service) return

  await service.marketingService.put('UpdateMarketingPackageTemplateOrder', {marketingPackageIds: requestBody.marketingPackageIds})
}

export default UpdateMarketingPackageTemplateOrder
