import { ApiRequestBody } from 'interfaces/ApiRequestBody'
import AgencyService from 'services/agenciesService'
import {
  UnconditionalDisplayAs,
  UnderOfferDisplayAs,
} from 'types/agencySettings'

interface UpdateContractSettingsRequest extends ApiRequestBody {
  contractsEmail?: string
  settlementPlace?: string
  titleEncumbrances?: string
  underOfferDisplayAs?: UnderOfferDisplayAs
  unconditionalDisplayAs?: UnconditionalDisplayAs
  AgencyId?: string
}

const UpdateContractSettings = async (
  requestBody: UpdateContractSettingsRequest
): Promise<number | undefined> => {
  const { getAuthToken, AgencyId, ...requestProperties } = requestBody

  if (!AgencyId) return

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.put(
    `UpdateContractSettings?AgencyId=${AgencyId}`,
    { ...requestProperties }
  )

  return result.status
}

export default UpdateContractSettings
