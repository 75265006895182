import { StaffApi } from 'helpers'

class StaffService {
  authToken: string
  staffService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.staffService = new StaffApi('staff', authToken)
  }
}

export default StaffService
