import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Agent } from 'types/agency'
import { GetAgentsInAgency } from 'services'
import { useAzureAuth } from './useAzureAuth'

const useAgentsInAgency = (): {
  agents?: Agent[]
  isLoading: boolean
  isFetched: boolean
} => {
  const { agencyId } = useParams()
  const [, getAuthToken] = useAzureAuth()
  const agencyQueryKey = ['agency-agents', agencyId]

  const {
    data: agents,
    isLoading,
    isFetched,
  } = useQuery<Agent[] | undefined>({
    queryKey: agencyQueryKey,
    queryFn: (ctx) =>
      GetAgentsInAgency(ctx.queryKey[1] as string, getAuthToken),
    enabled: !!agencyId,
  })

  return {
    agents,
    isLoading,
    isFetched,
  }
}

export default useAgentsInAgency
