import AgencyService from 'services/agenciesService'
import { ListingAgreementSettings } from 'types/agencySettings'

const getListingAgreementSettings = async (
  agencyId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<ListingAgreementSettings | undefined> => {
  if (!agencyId || !getAuthToken) return

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get(
    'GetListingAgreementSettings',
    {
      agencyId,
    }
  )

  return result.data
}

export default getListingAgreementSettings
