import {
  AgxRow,
  AgxColumn,
  AgxBodyText,
  Images,
  UploadedDocument,
} from '@urbanx/agx-ui-components'
import { useAzureAuth } from 'hooks'
import { formatFileSize } from 'utils/formatFileSize'
import { openFileInNewTab } from 'utils/openFileInNewTab'

interface Props {
  document: UploadedDocument
}
const DocumentRow = (props: Props) => {
  const { document } = props
  const [, getAuthToken] = useAzureAuth()

  return (
    <AgxRow key={document.documentId}>
      <AgxColumn extraClasses="file-document">
        <div
          className="file-document-container"
          onClick={() => openFileInNewTab(getAuthToken, document)}
        >
          <Images.FileTextOutline className="file-document-leftIcon" />
          <AgxBodyText medium extraClasses="file-document-body">
            <span className="file-document-name">{document.fileName}</span>
            <span className="file-document-size">
              {formatFileSize(document.fileSizeInBytes)}
            </span>
          </AgxBodyText>
          <Images.Download className="file-document-download" />
        </div>
      </AgxColumn>
      <AgxColumn extraClasses="file-document-date">
        <AgxBodyText>
          <span>
            {document.dateUploaded
              ? new Date(document.dateUploaded).toLocaleString('en-US')
              : ''}
          </span>
        </AgxBodyText>
      </AgxColumn>
    </AgxRow>
  )
}

export default DocumentRow
