import AgencyService from 'services/agenciesService'
import { ContractSettings } from 'types/agencySettings'

const getContractSettings = async (
  agencyId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<ContractSettings | undefined> => {
  if (!agencyId || !getAuthToken) return

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  const result = await service.agencyService.get('GetContractSettings', {
    agencyId,
  })

  return result.data
}

export default getContractSettings
