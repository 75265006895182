import React from 'react'
import {
  AgxBodyText,
  AgxColumn,
  AgxHeader,
  formatCurrency,
  formatPercentage,
} from '@urbanx/agx-ui-components'
import { RunRateInformation } from 'types/executiveStatistics'
import clsx from 'clsx'

interface TableRunRateProps {
  date: string
  runRateData: RunRateInformation
}

const TableRunRate = ({ date, runRateData }: TableRunRateProps) => {
  const [monthName, year] = date.split(' ')
  const parsedDate = new Date(`${monthName} 1, ${year}`)

  const formatDateRangeCurrentDay = (inputDate: Date) => {
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth()
    const inputYear = inputDate.getFullYear()
    const inputMonth = inputDate.getMonth()

    const monthLong = inputDate.toLocaleString('default', { month: 'long' })

    const lastDayOfMonth = new Date(inputYear, inputMonth + 1, 0).getDate()

    const endDay =
      inputYear === currentYear && inputMonth === currentMonth
        ? now.getDate()
        : lastDayOfMonth

    const formattedDateRange = `${monthLong} 1-${endDay} ${inputYear}`

    return formattedDateRange
  }

  const formattedDateRange = formatDateRangeCurrentDay(parsedDate)

  const tableData = [
    {
      title: 'Listings',
      mtd: runRateData?.mtdListings ?? '',
      runRate: runRateData?.runRateListings ?? '',
      historical: runRateData?.historicalListings ?? '',
      change: formatPercentage(
        runRateData?.changeListings?.toString() ?? '',
        2
      ),
      positiveChange:
        runRateData?.changeListings && runRateData.changeListings >= 0,
    },
    {
      title: 'Contracts',
      mtd: runRateData?.mtdContracts ?? '',
      runRate: runRateData?.runRateContracts ?? '',
      historical: runRateData?.historicalContracts ?? '',
      change: formatPercentage(
        runRateData?.changeContracts?.toString() ?? '',
        2
      ),
      positiveChange:
        runRateData?.changeContracts && runRateData.changeContracts >= 0,
    },
    {
      title: 'Sales Volume',
      mtd: formatCurrency(runRateData?.mtdSales ?? '', 0),
      runRate: formatCurrency(runRateData?.runRateSales ?? '', 0),
      historical: formatCurrency(runRateData?.historicalSales ?? '', 0),
      change: formatPercentage(runRateData?.changeSales?.toString() ?? '', 2),
      positiveChange: runRateData?.changeSales && runRateData.changeSales >= 0,
    },
    {
      title: 'Average Sale Price',
      mtd: formatCurrency(runRateData?.avgSalesPrice ?? '', 0),
      runRate: '',
      historical: formatCurrency(runRateData?.historicalAvgSalesPrice ?? '', 0),
      change: '',
    },
    {
      title: 'Conversion Rate',
      mtd: formatPercentage(runRateData?.conversionRate?.toString() ?? '', 0),
      runRate: '',
      historical: formatPercentage(
        runRateData?.historicalConversionRate?.toString() ?? '',
        0
      ),
      change: '',
    },
  ]

  return (
    <AgxColumn extraClasses="metrics-data">
      <AgxColumn>
        <AgxHeader size={3}>Run Rate</AgxHeader>
        <AgxBodyText medium>{formattedDateRange}</AgxBodyText>
      </AgxColumn>
      <div className="breakeven-table">
        <div className="breakeven-table-header">
          <AgxBodyText extraClasses="breakeven-blank">.</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">MTD</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">Run Rate</AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">
            {runRateData?.historicalHeading || 'No data'}
          </AgxBodyText>
          <AgxBodyText extraClasses="breakeven-header">Change %</AgxBodyText>
        </div>
        {tableData.map((row, index) => {
          return (
            <div key={index} className="breakeven-table-row">
              <AgxBodyText medium extraClasses="breakeven-cell left strong">
                {row.title}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell strong">
                {row.mtd}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell">
                {row.runRate}
              </AgxBodyText>
              <AgxBodyText extraClasses="breakeven-cell">
                {row.historical}
              </AgxBodyText>
              <AgxBodyText
                extraClasses={clsx(
                  'breakeven-cell',
                  'strong',
                  row.positiveChange === true && 'positive',
                  row.positiveChange === false && 'negative'
                )}
              >
                {row.change}
              </AgxBodyText>
            </div>
          )
        })}
      </div>
    </AgxColumn>
  )
}

export default TableRunRate
