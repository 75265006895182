import { QueryKey } from '@tanstack/react-query'
import AgencyService from 'services/agenciesService'
import { PartnershipDetails } from '@urbanx/agx-ui-components/dist/types/components/portal/Reporting/types'
import { AgxPartnershipLevel } from '@urbanx/agx-ui-components'
import moment from 'moment'

const staffGetPartnershipDetailsForAgency = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<PartnershipDetails> => {
  const token = await getAuthToken()
  if (!token) throw 'Token not available'

  const agencyId = queryKey[1] as string

  const service = new AgencyService(token)

  if (!service) throw 'Service not available'

  const result = await service.agencyService.get(
    'StaffGetPartnershipDetailsForAgency',
    {
      agencyId,
    }
  )

  return (
    result.data ||
    ({
      anniversaryDate: moment().toDate(),
      partnershipLevel: AgxPartnershipLevel.Bronze,
      targetLevelAdjustment: undefined,
    } as PartnershipDetails)
  )
}

export default staffGetPartnershipDetailsForAgency
