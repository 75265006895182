import { UploadedDocument } from '@urbanx/agx-ui-components'
import { StaffGetUploadedFileLink } from 'services'

export const openFileInNewTab = async (
  getAuthToken: () => Promise<string | void>,
  uploadedDocument?: UploadedDocument
) => {
  if (!uploadedDocument || !uploadedDocument.containerFilePath) return

  const documentLink = await StaffGetUploadedFileLink(
    uploadedDocument.containerFilePath,
    getAuthToken
  )

  if (!documentLink) {
    console.error('Error getting the file link')
    return
  }

  //Force download instead of opening the tab
  const anchor = document.createElement('a')
  anchor.id = 'pdfAnchor'
  anchor.href = documentLink
  anchor.download = 'true'
  anchor.target = '_blank'
  anchor.textContent = ''
  document.body.appendChild(anchor)
  anchor.click()
  setTimeout(function () {
    const pdfAnchor = document.getElementById('pdfAnchor')
    if (pdfAnchor) {
      document.body.removeChild(pdfAnchor)
    }
  }, 200)
}
