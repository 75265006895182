import { Validation } from "./agency"

export interface MarketingPackageTemplate {
    id: string
    order: number
    name: string
    includeRealEstateListing: boolean
    includeDomainListing: boolean
    displayItemisedPricingOnPdf: boolean
    items: string[]
    addOns: string[]
    displayName: string
    assignedAgentIds: string[]
    specialConditions?: string
  }

  export interface MarketingItemTemplate extends Validation {
    order?: number
    id?: string
    agencyId?: string
    productName: string
    itemType: string
    pricePerUnit?: number
    supplier?: string
    stockType?: string
  }

  export enum PackageItemType {
    Price = 'Price',
    Complimentary = 'Complimentary',
    AgentToFill = 'Agent to fill'
  }