import { Form, FormType } from '@urbanx/agx-ui-components'
import CampaignsService from 'services/campaignsService'

const getFormConfig = async (
  formType?: FormType,
  campaignId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<Form | undefined> => {
  if (!formType || !campaignId || !getAuthToken) return

  const token = await getAuthToken()

  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const { data: formConfig } = await service.campaignsService.get(
    `StaffGetFormConfiguration`,
    {
      FormType: formType,
      CampaignId: campaignId,
    }
  )

  return formConfig || {}
}

export default getFormConfig
