import MarketingService from 'services/marketingService'
import { MarketingItemTemplate } from 'types/marketing'

const SetMarketingItemTemplates = async ({
  agencyId,
  marketingItemTemplates,
  getAuthToken,
}: {
  agencyId: string
  marketingItemTemplates: MarketingItemTemplate[]
  getAuthToken: () => Promise<string | void>
}): Promise<number | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new MarketingService(token)

  if (!service) return

  const result = await service.marketingService.put('SetMarketingItemTemplates', {
    AgencyId: agencyId,
    MarketingItemTemplates: marketingItemTemplates,
  })

  return result.status
}

export default SetMarketingItemTemplates
