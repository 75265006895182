import { useContext, useState, useEffect, useMemo } from 'react'
import {
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxButton,
  AgxToast,
  cleanFullAddress,
} from '@urbanx/agx-ui-components'
import isEqualWith from 'lodash/isEqualWith'
import { ContentLayout, PageLayout } from 'layout'
import { UserContext } from 'contexts'
import { MenuTitles } from 'constants/menu'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useMutation } from '@tanstack/react-query'
import { SetCampaignOwnership } from 'services'
import { Permission } from 'types/agency'
import { AgxToastState } from 'types/commonTypes'
import { FormPrompt } from 'components/FormPrompt'
import { isDirtyCompare } from 'utils/compares'
import {
  useAgentsInAgency,
  useSelectedAgency,
  useSelectedCampaign,
} from 'hooks'
import AgentsSelector from './AgentsSelector'

const Permissions = () => {
  const user = useContext(UserContext)

  const { agency: selectedAgency } = useSelectedAgency()
  const { campaign: selectedCampaign, isLoading: isLoadingCampaign } =
    useSelectedCampaign()
  const { agents, isFetched } = useAgentsInAgency()

  const [isFormDirty, setIsFormDirty] = useState(false)
  const [, getAuthToken] = useAzureAuth()
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const initialPermission = useMemo<Permission>(
    () => ({
      leadAgentId: selectedCampaign?.leadAgentId,
      secondAgentId: selectedCampaign?.secondAgentId,
      campaignId: selectedCampaign?.campaignId,
      createdByUserId: selectedCampaign?.createdByUserId,
      assignedAgentIds: selectedCampaign?.assignedAgentIds ?? [],
    }),
    [selectedCampaign]
  )
  const [permission, setPermission] = useState<Permission>()

  useEffect(() => {
    setPermission(initialPermission)
  }, [initialPermission])

  useEffect(() => {
    setIsFormDirty(!isEqualWith(initialPermission, permission, isDirtyCompare))
  }, [permission])

  const { mutate: updateCampaignItems } = useMutation({
    mutationFn: SetCampaignOwnership,
    onSuccess: () => {
      setIsFormDirty(false)
      updateToastState({
        color: 'success',
        message: 'Role saved successfully',
        open: true,
      })
    },
  })

  const saveChanges = () => {
    updateCampaignItems({
      campaignId: selectedCampaign?.campaignId || '',
      leadAgentId: permission?.leadAgentId ?? '',
      secondAgentId: permission?.secondAgentId ?? '',
      assignedAgents: permission?.assignedAgentIds ?? [],
      getAuthToken,
    })
  }

  const activeAgents = useMemo(() => {
    return agents?.filter((a) => !a.softDeleted) || []
  }, [agents])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={`Campaigns / ${cleanFullAddress(
        selectedCampaign?.address
      )}`}
      isCampaignsSection={true}
    >
      <FormPrompt hasUnsavedChanges={isFormDirty} />
      <AgxToast selector="#agxToast" toastState={toastState} />
      <ContentLayout
        hasSecondaryMenu={true}
        secondaryActiveMenu={MenuTitles.PERMISSIONS}
        isCampaigns={true}
      >
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween extraClasses={'borderBottomContainer'}>
            <AgxRow largeGap>
              <AgxBodyText large>Edit Role</AgxBodyText>
            </AgxRow>
            <AgxRow largeGap>
              <AgxButton
                text="Save Changes"
                medium
                primary
                onClick={() => saveChanges()}
              />
            </AgxRow>
          </AgxRow>
          <div className="panelContainer">
            {!isLoadingCampaign &&
              isFetched &&
              selectedCampaign &&
              activeAgents &&
              permission?.createdByUserId && (
                <AgentsSelector
                  key={`agentsSelector-${selectedCampaign.campaignId}`}
                  agents={activeAgents ?? []}
                  permission={permission}
                  setPermission={setPermission}
                />
              )}
          </div>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default Permissions
