import { useEffect, use } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { CampaignDetailModel } from '@urbanx/agx-ui-components'
import { GetCampaignById } from 'services'
import { CampaignUpdatedEvent, NotificationType } from 'types/notification'
import { useAzureAuth } from './useAzureAuth'
import { NotificationContext } from 'contexts'

const useSelectedCampaign = (): {
  campaign?: CampaignDetailModel
  isLoading: boolean
  isFetched: boolean
} => {
  const { campaignId } = useParams()
  const queryClient = useQueryClient()
  const [, getAuthToken] = useAzureAuth()
  const notifications = use(NotificationContext)
  const campaignQueryKey = ['campaign-details', campaignId]

  const campaignUpdated = (event: CampaignUpdatedEvent) => {
    if (event.campaignId === campaignId) {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: campaignQueryKey }),
      ])
    }
  }

  useEffect(() => {
    notifications?.registerEvent(
      NotificationType.CampaignUpdatedEvent,
      campaignUpdated
    )

    return () => {
      notifications?.unregisterEvent(
        NotificationType.CampaignUpdatedEvent,
        campaignUpdated
      )
    }
  }, [])

  const {
    data: campaign,
    isLoading,
    isFetched,
  } = useQuery<CampaignDetailModel | undefined>({
    queryKey: campaignQueryKey,
    queryFn: (ctx) => GetCampaignById(ctx.queryKey[1] as string, getAuthToken),
    enabled: !!campaignId,
  })

  return {
    campaign,
    isLoading,
    isFetched,
  }
}

export default useSelectedCampaign
