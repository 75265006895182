import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, FormType, PageStatus } from '@urbanx/agx-ui-components'
import { GetFormConfig, SaveForm, SubmitForm } from 'services'
import { FormSubmissionResponse } from 'services/functions/campaigns/submitForm'
import { FormSaveResult } from 'services/functions/campaigns/saveForm'
import { useAzureAuth } from './useAzureAuth'
import useSelectedCampaign from './useSelectedCampaign'

interface SaveFormOptions {
  onSuccess: (response?: FormSaveResult) => void
  onError: () => void
}

interface SubmitFormOptions {
  onSuccess: (response?: FormSubmissionResponse) => void
  onError: () => void
}

const useFormConfig = (
  formType: FormType
): {
  formConfig?: Form
  isLoading: boolean
  isFetched: boolean
  saveForm: (
    formValues: object,
    pageStatuses: PageStatus[],
    options: SaveFormOptions
  ) => void
  submitForm: (
    formValues: object,
    notifyAgent: boolean,
    options: SubmitFormOptions
  ) => void
} => {
  const [, getAuthToken] = useAzureAuth()
  const queryClient = useQueryClient()
  const { campaign } = useSelectedCampaign()
  const formConfigQueryKey = ['form-config', formType, campaign?.campaignId]

  const {
    data: formConfig,
    isLoading,
    isFetched,
  } = useQuery<Form | undefined>({
    queryKey: formConfigQueryKey,
    queryFn: (ctx) =>
      GetFormConfig(
        ctx.queryKey[1] as FormType,
        ctx.queryKey[2] as string,
        getAuthToken
      ),
    enabled: !!campaign?.campaignId,
    gcTime: 0,
  })

  const { mutate: saveFormValues } = useMutation({ mutationFn: SaveForm })

  const saveForm = (
    formValues: object,
    pageStatuses: PageStatus[],
    options: SaveFormOptions
  ) => {
    if (!campaign?.campaignId || !campaign?.state) return

    saveFormValues(
      {
        CampaignId: campaign.campaignId,
        FormType: formType,
        State: campaign.state,
        FormData: formValues,
        PageStatuses: pageStatuses,
        getAuthToken,
      },
      {
        onSuccess: async (data) => {
          await queryClient.invalidateQueries({
            queryKey: formConfigQueryKey,
          })

          if (options?.onSuccess) options.onSuccess(data)
        },
        onError: options?.onError,
      }
    )
  }

  const { mutate: submitFormValues } = useMutation({ mutationFn: SubmitForm })

  const submitForm = (
    formValues: object,
    notifyAgent: boolean,
    options: SubmitFormOptions
  ) => {
    if (!campaign?.campaignId || !campaign?.state) return

    submitFormValues(
      {
        CampaignId: campaign.campaignId,
        FormType: formType,
        State: campaign.state,
        BlankContractIsLive: !campaign.blankContractIsLive,
        NotifyAgent: notifyAgent,
        FormData: formValues,
        getAuthToken,
      },
      {
        onSuccess: async (data) => {
          await queryClient.invalidateQueries({
            queryKey: formConfigQueryKey,
          })

          if (options?.onSuccess) options.onSuccess(data)
        },
        onError: options?.onError,
      }
    )
  }

  return {
    formConfig,
    isLoading,
    isFetched,
    saveForm,
    submitForm,
  }
}

export default useFormConfig
