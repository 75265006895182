import Popup, { ActionButton } from '../Popup/Popup'
import './deleteConfirmation.scss'

interface DeleteConfirmationProps {
  name: string
  title: string
  lineOne: string
  lineTwo: string
  showPopUp: boolean
  primaryActionTitle: string
  secondaryActionTitle: string
  onConfirm: () => void
  onClose: () => void
}

const DeleteConfirmation = (props: DeleteConfirmationProps) => {
  const {
    showPopUp,
    name,
    title,
    lineOne,
    lineTwo,
    onConfirm,
    onClose,
    primaryActionTitle,
    secondaryActionTitle,
  } = props

  const actionButtons: ActionButton[] = [
    {
      title: primaryActionTitle,
      isPrimary: true,
      clickHandler: () => onConfirm(),
    },
    {
      title: secondaryActionTitle,
      clickHandler: () => onClose(),
    },
  ]

  return (
    <Popup
      title={title}
      isOpen={showPopUp}
      onClose={() => onClose()}
      actionButtons={actionButtons}
      size={{ widthInPX: 640 }}
    >
      <div>
        <p className="contenrtStyle__deleteConfirmation">
          {lineOne}{' '}
          <span className="usernameStyle__deleteConfirmation">{name}</span>?
        </p>
        <p className="contenrtStyle__deleteConfirmation">{lineTwo}</p>
      </div>
    </Popup>
  )
}

export default DeleteConfirmation
