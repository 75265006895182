import { QueryKey } from '@tanstack/react-query'
import MarketingService from 'services/marketingService'
import { MarketingItemTemplate } from 'types/marketing'

const StaffGetMarketingItemTemplates = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<MarketingItemTemplate[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const agencyId = queryKey[1] as string
  const service = new MarketingService(token)

  if (!service) return

  const result = await service.marketingService.get('StaffGetMarketingItemTemplates', { agencyId })

  return result.data
}

export default StaffGetMarketingItemTemplates
