import { QueryKey } from '@tanstack/react-query'
import RexService from 'services/rexService'
import { ContractDateToUse, StaffGetEntityNumbersResponse } from 'types/rex'

const staffGetEntityNumbers = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<StaffGetEntityNumbersResponse | undefined> => {
  const token = await getAuthToken()
  if (!token) return undefined

  const entityId = queryKey[1] as string
  const contractDateToUse = queryKey[2] as ContractDateToUse
  const fromDate = queryKey[3] as string
  const toDate = queryKey[4] as string
  const isIndividual = queryKey[5] as boolean

  const service = new RexService(token)

  if (!service) return undefined

  const result = await service.rexService.get('StaffGetEntityNumbers', {
    entityId,
    contractDateToUse,
    fromDate,
    toDate,
    isIndividual,
  })

  return result.data
}

export default staffGetEntityNumbers
