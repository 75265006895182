import {
  AgxBodyText,
  AgxButton,
  AgxColours,
  AgxColumn,
  AgxMultiSelect,
  AgxRow,
  AgxTextInput,
  EntityImage,
  Images,
} from '@urbanx/agx-ui-components'
import clsx from 'clsx'
import { Team, TeamMember, UploadedDocument } from 'types/agency'
import { useEffect, useState } from 'react'
import { PlaceHolderImage } from 'components'
import FileUpload from 'components/file-upload/FileUpload'
import { useMutation } from '@tanstack/react-query'
import { UploadPublicDocument } from 'services'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useParams } from 'react-router-dom'
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core'
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable'
import SortableItem from 'components/sortable-item/SortableItem'
import './addTeam.scss'

interface AddTeamProps extends Team {
  index: number
  agents: TeamMember[]
  updateTeamName: (id: string, value: string) => void
  updateTeamMembers: (id: string, value: string) => void
  updateTeamPhoto: (id: string, photoUri: string) => void
  onDeleteTeamHandler: (id: string) => void
}

const AddTeam = (props: AddTeamProps) => {
  const {
    id,
    name,
    agents,
    members,
    photoUri,
    index,
    updateTeamName,
    updateTeamMembers,
    updateTeamPhoto,
    onDeleteTeamHandler,
  } = props

  const [orderedMembersList, setOrderedMembersList] = useState<string[]>([])
  const [img, updateImg] = useState(photoUri ?? '')
  const [, setLogo] = useState<UploadedDocument | undefined>(undefined)
  const [, getAuthToken] = useAzureAuth()
  const { agencyId } = useParams()

  const { mutate: uploadPublicDocument } = useMutation({
    mutationFn: UploadPublicDocument,
    onSuccess: (r) => {
      updateImg(r?.publicPath || '')
      updateTeamPhoto(id, r?.publicPath || '')
      setLogo(r)
    },
  })

  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      if (orderedMembersList && orderedMembersList?.length > 0) {
        setOrderedMembersList((items) => {
          const oldIndex = items.indexOf(active.id.toString())
          const newIndex = items.indexOf(over?.id.toString() ?? '') || 0
          return arrayMove(items, oldIndex, newIndex)
        })
      }
    }
  }

  useEffect(() => {
    if (members) {
      setOrderedMembersList(members)
    }
  }, [members])

  const useAgentImage = (name: string, photo?: UploadedDocument) => {
    updateTeamName(id, name)
    if (photo) {
      updateImg(photo.publicPath)
      updateTeamPhoto(id, photo.publicPath)
    } else {
      updateImg('')
      updateTeamPhoto(id, '')
    }
  }

  const toggleOpacity = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    opacity: number
  ) => {
    event.currentTarget.style.setProperty('opacity', `${opacity}`)
  }
  const classes = clsx('dragDropItem', 'teamMemberItem')

  const renderItem = (memberId: string, index: number) => {
    const member = agents.find((agent) => agent.id === memberId)
    if (member) {
      return (
        <SortableItem
          key={memberId || `dragItem-${index}`}
          id={memberId || `dragItem-${index}`}
          extraClasses={classes}
        >
          <AgxRow spaceBetween centered veryLargeGap>
            <div className="thPackageNameContainer__marketing">
              {member.photo ? (
                <img
                  src={member.photo.publicPath}
                  style={{
                    height: 45,
                    width: 45,
                    borderRadius: '50%',
                    marginRight: 16,
                  }}
                />
              ) : (
                <PlaceHolderImage size={45} title={member.firstName} />
              )}
              <AgxBodyText small>
                {member.firstName} {member.lastName}
              </AgxBodyText>
            </div>
            <div
              id={`${member.id}`}
              style={{
                display: 'flex',
                opacity: 0.5,
                width: '150px',
                justifyContent: 'flex-end',
              }}
              onMouseOver={(event) => toggleOpacity(event, 1)}
              onMouseOut={(event) => toggleOpacity(event, 0.5)}
            >
              <AgxButton
                text="Use"
                medium
                naked
                onClick={() =>
                  useAgentImage(
                    `${member.firstName} ${member.lastName}`,
                    member.photo
                  )
                }
              />
            </div>
          </AgxRow>
        </SortableItem>
      )
    }
  }

  const updateOrderedMemberList = (value: string) => {
    const memberIds = value.split(',')
    // team member added
    if (memberIds.length > orderedMembersList.length) {
      const newMember = memberIds.filter(
        (member) => !orderedMembersList.includes(member)
      )
      setOrderedMembersList([...newMember, ...orderedMembersList])
    }

    // team member removed
    if (memberIds.length < orderedMembersList.length) {
      const memberRemoved = orderedMembersList.filter(
        (member) => !memberIds.includes(member)
      )
      setOrderedMembersList(
        orderedMembersList.filter((member) => member !== memberRemoved[0])
      )
    }
  }

  const uploadLogo = (file: File) => {
    updateImg(URL.createObjectURL(file))
    uploadPublicDocument({
      selectedFile: file,
      AgencyId: agencyId,
      getAuthToken,
    })
  }

  return (
    <AgxColumn extraLargeGap extraClasses={'addTeamsContainer__manageTeams'}>
      <AgxRow veryLargeGap>
        <AgxTextInput
          id="txtTeamName"
          label=""
          noOptionalLabel
          placeholder={`Team ${index}`}
          leftIcon={<Images.PeopleBlack />}
          stretch
          defaultValue={name}
          parentControlValue
          onInputValueChange={({ value }: { value: string }) =>
            updateTeamName(id, value)
          }
        />
        <AgxButton
          text=""
          small
          naked
          onClick={() => onDeleteTeamHandler(id)}
        />
      </AgxRow>
      <AgxRow largeGap extraClasses="flexCentre">
        <EntityImage
          name={name}
          addRingToImage
          backgroundColour={`var(--${AgxColours.NEUTRAL_GREY_500})`}
          photoUri={img}
          size={72}
          usePlaceholderImage
        />
        <FileUpload
          id="fileUpload"
          title=""
          buttonText={img ? 'Change' : 'Upload'}
          wide
          extensions={['jpg', 'jpeg', 'png', 'svg', 'bmp']}
          uploadFile={(file) => uploadLogo(file)}
          dark
          hideIcon
        />
        {img && (
          <AgxButton
            text="Remove"
            medium
            hollow
            primary
            onClick={() => {
              updateImg('')
              updateTeamPhoto(id, '')
              setLogo(undefined)
            }}
          />
        )}
      </AgxRow>
      <div key={`team-${id}-${index}`} className="teamSection__manageTeams">
        <AgxMultiSelect
          id={`team-${id}-${index}`}
          label="Team Members"
          hideOptionalLabel
          defaultValue={orderedMembersList}
          onValueChanged={({ value }: { value: string[] }) => {
            updateOrderedMemberList(value.join(','))
            updateTeamMembers(id, value.join(','))
          }}
          options={agents.map((a) => ({
            value: a.id,
            label: `${a.firstName} ${a.lastName}`,
          }))}
        />
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleOnDragEnd}
        >
          <SortableContext
            items={orderedMembersList
              .map((item) => item)
              .filter((id): id is string => id !== undefined)}
            strategy={verticalListSortingStrategy}
          >
            {orderedMembersList &&
              orderedMembersList.map((memberId, index) =>
                renderItem(memberId, index)
              )}
          </SortableContext>
        </DndContext>
      </div>
    </AgxColumn>
  )
}

export default AddTeam
