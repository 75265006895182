import { UploadedDocument } from '@urbanx/agx-ui-components'

export enum CommissionAdjustmentSalePriceReduced {
  ConfirmWithAgent = 0,
  Original = 1,
  Reduced = 2,
}

export interface CommissionSettings {
  commissionAdjustmentSalePriceReduced?: CommissionAdjustmentSalePriceReduced
}

export enum UnderOfferDisplayAs {
  Available = 0,
  UnderOffer = 1,
}

export enum UnconditionalDisplayAs {
  UnderOffer = 0,
  Sold = 1,
}

export interface ContractSettings {
  contractsEmail?: string
  settlementPlace?: string
  titleEncumbrances?: string
  underOfferDisplayAs?: UnderOfferDisplayAs
  unconditionalDisplayAs?: UnconditionalDisplayAs
}

export interface ListingAgreementSettings {
  privacyPolicyLink?: string
}

export interface IBranding {
  id: number
  backgroundColor: string
  backgroundTextColor: string
  accentColor: string
  buttonTextColor: string
  inboxBackgroundColor: string
  inboxBackgroundColorOpacity: number
  sidebarBackgroundColor: string
  sidebarBackgroundColorOpacity: number
  logo?: UploadedDocument
}

export class Branding implements IBranding {
  id: number
  backgroundColor: string
  backgroundTextColor: string
  accentColor: string
  buttonTextColor: string
  inboxBackgroundColor: string
  inboxBackgroundColorOpacity: number
  sidebarBackgroundColor: string
  sidebarBackgroundColorOpacity: number
  logo?: UploadedDocument
  constructor() {
    this.id = 0
    this.backgroundColor = '#FFFFFF'
    this.backgroundTextColor = '#000000'
    this.accentColor = '#000000'
    this.buttonTextColor = '#000000'
    this.inboxBackgroundColor = '#FFFFFF'
    this.inboxBackgroundColorOpacity = 0
    this.sidebarBackgroundColor = '#FFFFFF'
    this.sidebarBackgroundColorOpacity = 0
    this.logo = undefined
  }
}
