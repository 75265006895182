import axios, { AxiosRequestHeaders } from 'axios'
import { v4 as uuidv4 } from 'uuid'

interface RequestParameters {
  [key: string]: string | string[] | number | boolean
}

export class StaffApi {
  baseUrl: string
  authToken: string
  headers: AxiosRequestHeaders | object | null

  constructor(
    service: string,
    authToken: string,
    uri: string | null = null,
    headers: AxiosRequestHeaders | object | null = {}
  ) {
    const baseUri = uri ?? import.meta.env.VITE_REACT_APP_AGENTX_BASE_API

    this.baseUrl = `${baseUri}/${service}/`

    this.authToken = authToken

    this.headers = headers
  }

  get(url: string, parameters: RequestParameters = {}) {
    return axios.get(`${this.baseUrl}${url}`, this.createParameters(parameters))
  }

  post(url: string, body: unknown, parameters = {}, overrideHeaders = {}) {
    return axios.post(
      `${this.baseUrl}${url}`,
      body,
      this.createParameters(parameters, overrideHeaders)
    )
  }

  put(url: string, body: unknown) {
    return axios.put(`${this.baseUrl}${url}`, body, this.createParameters())
  }

  patch(url: string, body: unknown) {
    return axios.patch(`${this.baseUrl}${url}`, body, this.createParameters())
  }

  delete(url: string) {
    return axios.delete(`${this.baseUrl}${url}`, this.createParameters())
  }

  createParameters = (parameters = {}, overrideHeaders = {}) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + this.authToken,
        'x-session-id': sessionStorage.getItem(http.sessionName) ?? uuidv4(),
        ...{ ...this.headers, ...overrideHeaders },
      },
      params: { ...parameters},
      paramsSerializer: (params: RequestParameters): string => {
        return Object.keys(params)
          .map((key) => {
            const value = params[key];
            return Array.isArray(value) ?
              value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&') :
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          })
          .join('&');
      }
    }

    return config
  }
}

export const http = {
  get(url: string) {
    return axios.get(url)
  },
  post(url: string, body: unknown) {
    return axios.post(url, body)
  },
  put(url: string, body: unknown) {
    return axios.put(url, body)
  },
  patch(url: string, body: unknown) {
    return axios.patch(url, body)
  },
  delete(url: string) {
    return axios.delete(url)
  },
  sessionName: 'autopilot-sessionId',
}
