import { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import {
  AgxButton,
  AgxTextInput,
  AgxCheckbox,
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxTextArea,
  Images,
  AgxMultiSelect,
  MarketingItemType,
} from '@urbanx/agx-ui-components'
import Popup from 'components/Popup/Popup'
import { Agent } from 'types/agency'
import DeleteConfirmation from 'components/delete-confirmation/DeleteConfirmation'
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core'
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  UpdateMarketingPackageTemplate,
  CreateMarketingPackageTemplate,
  DeleteMarketingPackageTemplate,
} from 'services'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import './editMarketingPackage.scss'
import xorWith from 'lodash/xorWith'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import {
  MarketingPackageTemplate,
  MarketingItemTemplate,
} from 'types/marketing'
import SortableItem from 'components/sortable-item/SortableItem'

interface EditMarketingProps {
  onBack: () => void
  marketingPackage: MarketingPackageTemplate | undefined
  marketingPackageOrder: number
  includedStandardInclusions: string[]
  includedAddons: string[]
  allItems: MarketingItemTemplate[]
  agencyId: string
  allAvailableAgents: Agent[]
}

const enum InclusionsType {
  STANDARD = 'standard',
  ADDONS = 'addons',
}

const STANDARD_INCLUSIONS_TITLE = 'Select standard inclusions to add'
const ADDON_INCLUSIONS_TITLE = 'Select add-ons to add'
const REA_LISTING_LABEL = 'REA Listing'
const DOMAIN_LISTING_LABEL = 'Domain Listing'
const SELECT_ALL_LABEL = 'Select All'
const REA_LISTING: MarketingItemTemplate = {
  id: REA_LISTING_LABEL,
  productName: REA_LISTING_LABEL,
  itemType: MarketingItemType.AgentToFill,
}
const DOMAIN_LISTING: MarketingItemTemplate = {
  id: DOMAIN_LISTING_LABEL,
  productName: DOMAIN_LISTING_LABEL,
  itemType: MarketingItemType.AgentToFill,
}
const SELECT_ALL: MarketingItemTemplate = {
  id: SELECT_ALL_LABEL,
  productName: SELECT_ALL_LABEL,
  itemType: MarketingItemType.Custom,
}

const EditMarketingPackage = (props: EditMarketingProps) => {
  const {
    onBack,
    marketingPackage,
    marketingPackageOrder,
    includedStandardInclusions,
    includedAddons,
    allItems,
    agencyId,
    allAvailableAgents,
  } = props
  const [standardItems, setStandardItems] = useState<MarketingItemTemplate[]>(
    []
  )
  const [addons, setAddons] = useState<MarketingItemTemplate[]>([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [packageName, setPackageName] = useState('')
  const [packageDisplayName, setPackageDisplayName] = useState('')
  const [addInclusionsTitle, setAddInclusionsTitle] = useState('')
  const [specialConditions, setSpecialConditions] = useState('')
  const [showEditInclusions, setShowEditInclusions] = useState(false)
  const [isItemisedPricing, setIsItemisedPricing] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [forceCheck, setForceCheck] = useState(false)
  const [standardItemsSelectAll, setStandardItemsSelectAll] = useState(false)
  const [addonsSelectAll, setAddonsSelectAll] = useState(false)
  const [isStandardItemsIndeterminate, setIsStandardItemsIndeterminate] =
    useState(false)
  const [isAddonsIndeterminate, setIsAddonsIndeterminate] = useState(false)

  const allAgentOptions = useMemo(() => {
    return allAvailableAgents.map((agent) => ({
      value: agent.id,
      label: `${agent.name?.firstName} ${agent.name?.lastName}`,
    }))
  }, [allAvailableAgents])

  const inclusionsList = useMemo(() => {
    return allItems
      .filter((i) => !addons.find((a) => a.id === i.id))
      .sort((a, b) => {
        const sortedItems = standardItems.map((item) => item.id)
        return sortedItems.indexOf(a.id) - sortedItems.indexOf(b.id)
      })
      .reduce(
        (acc, curr) => {
          acc.push(curr)
          return acc
        },
        [SELECT_ALL, REA_LISTING, DOMAIN_LISTING]
      )
  }, [allItems, addons])

  const addonsList = useMemo(() => {
    return allItems
      .filter((i) => !standardItems.find((s) => s.id === i.id))
      .sort((a, b) => {
        const sortedAddons = addons.map((addon) => addon.id)
        return sortedAddons.indexOf(a.id) - sortedAddons.indexOf(b.id)
      })
      .reduce(
        (acc, curr) => {
          acc.push(curr)
          return acc
        },
        [SELECT_ALL]
      )
  }, [allItems, standardItems])

  const [userAccessIds, setUserAccessIds] = useState<string[]>(
    marketingPackage?.assignedAgentIds || []
  )

  const [, getAuthToken] = useAzureAuth()

  const queryClient = useQueryClient()
  const { mutate: updatePackage } = useMutation({
    mutationFn: UpdateMarketingPackageTemplate,
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
      onBack()
    },
  })
  const { mutate: addPackage } = useMutation({
    mutationFn: CreateMarketingPackageTemplate,
    onSuccess: () => {
      setIsFormDirty(false)
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
      onBack()
    },
  })
  const { mutate: deletePackage } = useMutation({
    mutationFn: DeleteMarketingPackageTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
      onBack()
    },
  })

  useEffect(() => {
    checkSelectAllState(standardItems, inclusionsList, InclusionsType.STANDARD)
  }, [standardItems])

  useEffect(() => {
    checkSelectAllState(addons, addonsList, InclusionsType.ADDONS)
  }, [addons])

  useEffect(() => {
    if (standardItemsSelectAll) {
      setStandardItems([
        ...inclusionsList.filter((i) => i.id !== SELECT_ALL_LABEL),
      ])
    } else {
      setStandardItems([])
    }
  }, [standardItemsSelectAll])

  useEffect(() => {
    if (addonsSelectAll) {
      setAddons([...addonsList.filter((i) => i.id !== SELECT_ALL_LABEL)])
    } else {
      setAddons([])
    }
  }, [addonsSelectAll])

  useEffect(() => {
    if (includedStandardInclusions) {
      const standardItems: MarketingItemTemplate[] = includedStandardInclusions
        .map((inclusion) => allItems.find((item) => item.id === inclusion))
        .filter((item) => item !== undefined)
        .map((item) => item as MarketingItemTemplate)

      if (marketingPackage?.includeRealEstateListing)
        standardItems.splice(0, 0, REA_LISTING)

      if (marketingPackage?.includeDomainListing)
        standardItems.splice(1, 0, DOMAIN_LISTING)

      setStandardItems(standardItems)
    }

    if (includedAddons) {
      setAddons(
        includedAddons.flatMap((addon) => {
          const item = allItems.find((item) => item.id === addon)
          return item !== undefined ? [item as MarketingItemTemplate] : []
        })
      )
    }

    if (marketingPackage) {
      setPackageName(marketingPackage.name || '')
      setPackageDisplayName(marketingPackage.displayName || '')
      setSpecialConditions(marketingPackage?.specialConditions || '')
    }
  }, [includedStandardInclusions, includedAddons, marketingPackage])

  const onDeleteItemHandler = () => {
    setShowDeleteConfirmation(true)
  }

  const onConfirmDelete = useCallback(() => {
    if (marketingPackage?.id) {
      deletePackage({
        AgencyId: agencyId,
        MarketingPackageId: marketingPackage.id,
        getAuthToken,
      })
    } else {
      onBack()
    }
  }, [marketingPackage?.id, agencyId, deletePackage, onBack])

  const handleOnDragEndStandard = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (active.id !== over?.id) {
        setStandardItems((prev) => {
          const oldIndex = prev.findIndex((item) => item.id === active.id)
          const newIndex = prev.findIndex((item) => item.id === over?.id)
          if (prev !== standardItems) setIsFormDirty(true)
          return arrayMove(prev, oldIndex, newIndex)
        })
      }
    },
    [standardItems, setStandardItems]
  )

  const handleOnDragEndAddons = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (active.id !== over?.id) {
        setAddons((prev) => {
          const oldIndex = prev.findIndex((item) => item.id === active.id)
          const newIndex = prev.findIndex((item) => item.id === over?.id)
          if (prev !== addons) setIsFormDirty(true)
          return arrayMove(prev, oldIndex, newIndex)
        })
      }
    },
    [addons, setAddons]
  )

  const editInclusions = useCallback(
    (inclusionsType: InclusionsType) => {
      if (inclusionsType === InclusionsType.STANDARD) {
        setAddInclusionsTitle(STANDARD_INCLUSIONS_TITLE)
      } else {
        setAddInclusionsTitle(ADDON_INCLUSIONS_TITLE)
      }
      setShowEditInclusions(true)
    },
    [setAddInclusionsTitle, setShowEditInclusions]
  )

  const onSavePackageChanges = () => {
    const itemsList: string[] = standardItems
      .filter((i) => !!i.id)
      .map((i) => i.id!)
    const addonsList: string[] = addons.filter((i) => !!i.id).map((i) => i.id!)

    const isReaListing = !!standardItems.find(
      (i) => i.productName === REA_LISTING_LABEL
    )
    const isDomainListing = !!standardItems.find(
      (i) => i.productName === DOMAIN_LISTING_LABEL
    )
    if (marketingPackage?.id) {
      updatePackage({
        Id: marketingPackage?.id,
        Order: marketingPackageOrder,
        AgencyId: agencyId,
        Name: packageName,
        DisplayName: packageDisplayName,
        IncludeRealEstateListing: isReaListing,
        IncludeDomainListing: isDomainListing,
        DisplayItemisedPricingOnPdf: isItemisedPricing,
        Items: itemsList,
        AddOns: addonsList,
        assignedAgentIds: userAccessIds,
        specialConditions: specialConditions,
        getAuthToken,
      })
    } else {
      addPackage({
        AgencyId: agencyId,
        Order: marketingPackageOrder,
        Name: packageName,
        DisplayName: packageDisplayName,
        IncludeRealEstateListing: isReaListing,
        IncludeDomainListing: isDomainListing,
        DisplayItemisedPricingOnPdf: isItemisedPricing,
        Items: itemsList,
        AddOns: addonsList,
        specialConditions: specialConditions,
        assignedAgentIds: userAccessIds,
        getAuthToken,
      })
    }
  }

  // Set the select all and indeterminate state based on the inclusions list
  const checkSelectAllState = (
    inclusionItems: MarketingItemTemplate[],
    list: MarketingItemTemplate[],
    type: InclusionsType
  ) => {
    if (inclusionItems.length === 0) {
      if (type === InclusionsType.STANDARD) {
        setStandardItemsSelectAll(false)
        setIsStandardItemsIndeterminate(false)
      } else {
        setAddonsSelectAll(false)
        setIsAddonsIndeterminate(false)
      }
    } else if (
      inclusionItems.length ===
        list.filter((i) => i.id !== SELECT_ALL_LABEL).length &&
      isEmpty(
        xorWith(
          inclusionItems,
          list.filter((i) => i.id !== SELECT_ALL_LABEL),
          isEqual
        )
      )
    ) {
      if (type === InclusionsType.STANDARD) {
        setStandardItemsSelectAll(true)
        setIsStandardItemsIndeterminate(false)
        setIsAddonsIndeterminate(false)
      } else {
        setAddonsSelectAll(true)
        setIsAddonsIndeterminate(false)
        setIsStandardItemsIndeterminate(false)
      }
    } else {
      if (type === InclusionsType.STANDARD) {
        setIsStandardItemsIndeterminate(true)
      } else {
        setIsAddonsIndeterminate(true)
      }
    }
  }

  const renderEditInclusions = (item: MarketingItemTemplate) => {
    let checked = false

    if (item.id === SELECT_ALL_LABEL) {
      checked =
        addInclusionsTitle === STANDARD_INCLUSIONS_TITLE
          ? standardItemsSelectAll
          : addonsSelectAll
    } else {
      if (addInclusionsTitle === STANDARD_INCLUSIONS_TITLE) {
        checked = standardItems.some((s) => s.id === item.id)
      } else {
        checked = addons.some((s) => s.id === item.id)
      }
    }

    const updateInclusionsList = (value: boolean) => {
      const isStandardInclusionsList =
        addInclusionsTitle === STANDARD_INCLUSIONS_TITLE

      // Set the type of inclusions list
      const inclusionsListItems = isStandardInclusionsList
        ? standardItems
        : addons

      if (value) {
        if (item.id === SELECT_ALL_LABEL) {
          if (addInclusionsTitle === STANDARD_INCLUSIONS_TITLE)
            setStandardItemsSelectAll(value)
          else setAddonsSelectAll(value)
        } else {
          const alreadyExist = inclusionsListItems.some((i) => i.id === item.id)
          if (!alreadyExist) {
            setIsFormDirty(true)

            if (isStandardInclusionsList)
              setStandardItems([...inclusionsListItems, item])
            else setAddons([...inclusionsListItems, item])
          }
        }
      } else {
        if (item.id === SELECT_ALL_LABEL) {
          if (addInclusionsTitle === STANDARD_INCLUSIONS_TITLE)
            setStandardItemsSelectAll(value)
          else setAddonsSelectAll(value)
        } else {
          if (
            JSON.stringify(inclusionsListItems) !==
            JSON.stringify(inclusionsListItems.filter((i) => i.id !== item.id))
          ) {
            setIsFormDirty(true)
          }

          const filteredItems = inclusionsListItems.filter(
            (i) => i.id !== item.id
          )

          if (isStandardInclusionsList) setStandardItems(filteredItems)
          else setAddons(inclusionsListItems.filter((i) => i.id !== item.id))
        }
      }
    }

    return (
      <AgxRow
        key={`${item.id}`}
        spaceBetween
        extraClasses="borderBottomContainer checkboxContainer"
      >
        <AgxCheckbox
          id="chkProductName"
          indeterminate={
            item.id === SELECT_ALL_LABEL &&
            (addInclusionsTitle === STANDARD_INCLUSIONS_TITLE
              ? isStandardItemsIndeterminate
              : isAddonsIndeterminate)
          }
          subLabel={item.productName}
          naked
          parentControlValue
          defaultValue={checked}
          onValueChanged={({ value }: { value: boolean }) =>
            updateInclusionsList(value)
          }
        />
        <span>
          {item.pricePerUnit ? `$${item.pricePerUnit.toFixed(2)}` : '---'}
        </span>
      </AgxRow>
    )
  }

  const marketingPackageNonDraggableItemClasses = clsx(
    'borderBottomContainer',
    'marketingPackageNonDraggableItem'
  )
  const marketingPackageItemClasses = clsx(
    'dragDropItem',
    'marketingPackageItems'
  )

  const renderItem = (
    item: MarketingItemTemplate,
    index: number,
    isDraggable: boolean
  ) => {
    if (!isDraggable) {
      return (
        <AgxRow
          key={`item-${index}`}
          spaceBetween
          extraClasses={marketingPackageNonDraggableItemClasses}
        >
          <div className="thPackageNameContainer__marketing">
            <AgxBodyText small>{item.productName}</AgxBodyText>
          </div>
          <AgxBodyText small>
            {item.pricePerUnit ? `$${item.pricePerUnit.toFixed(2)}` : '---'}
          </AgxBodyText>
        </AgxRow>
      )
    }
    return (
      <SortableItem
        key={item.id || `dragItem-${index}`}
        id={item.id || `dragItem-${index}`}
        extraClasses={marketingPackageItemClasses}
      >
        <AgxRow spaceBetween>
          <div className="thPackageNameContainer__marketing">
            <AgxBodyText small>{item.productName}</AgxBodyText>
          </div>
          <div>
            <AgxBodyText small>
              {item.pricePerUnit ? `$${item.pricePerUnit.toFixed(2)}` : '---'}
            </AgxBodyText>
          </div>
        </AgxRow>
      </SortableItem>
    )
  }

  const onBackClick = () => {
    if (isFormDirty) setForceCheck(true)
    else onBack()
  }

  return (
    <AgxColumn veryLargeGap extraClasses="growOne">
      <FormPrompt
        hasUnsavedChanges={isFormDirty}
        forceCheck={forceCheck}
        resetForceCheck={(value: boolean) => setForceCheck(value)}
        onBack={onBack}
      />
      <AgxRow veryLargeGap spaceBetween extraClasses="borderBottomContainer">
        <AgxButton
          text="Marketing Package"
          large
          naked
          onClick={() => onBackClick()}
        />
        <AgxRow veryLargeGap>
          <AgxButton
            text="Delete package"
            medium
            naked
            danger
            onClick={onDeleteItemHandler}
          />
          <AgxButton
            text="Save Changes"
            medium
            primary
            onClick={onSavePackageChanges}
          />
        </AgxRow>
      </AgxRow>
      <AgxRow veryLargeGap fill>
        <AgxColumn fill extraLargeGap extraClasses="checkboxContainer">
          <AgxTextInput
            id="txtPackageName"
            stretch
            label="Package name"
            noOptionalLabel
            defaultValue={packageName}
            onInputValueChange={({ value }: { value: string }) => {
              if (value && packageName !== value) {
                setIsFormDirty(true)
              }
              setPackageName(value)
            }}
            parentControlValue={true}
          />
          <AgxTextInput
            id="txtSearch"
            stretch
            label="Display name on PDF"
            noOptionalLabel
            placeholder={packageName}
            defaultValue={packageDisplayName}
            onInputValueChange={({ value }: { value: string }) => {
              if (value && packageDisplayName !== value) {
                setIsFormDirty(true)
              }
              setPackageDisplayName(value)
            }}
            parentControlValue={true}
          />
          <AgxMultiSelect
            id={`userAccess-${marketingPackage?.id}`}
            label="User Access"
            hideOptionalLabel
            defaultValue={userAccessIds}
            onValueChanged={({ value }: { value: string[] }) => {
              if (!isEqual(userAccessIds, value)) {
                setIsFormDirty(true)
              }
              setUserAccessIds(value ?? [])
            }}
            options={allAgentOptions}
          />
        </AgxColumn>
        <AgxColumn fill extraLargeGap extraClasses="checkboxContainer">
          <AgxTextArea
            id="agx-docuSignEmailMessage"
            label="Special Conditions"
            placeholder="Nil"
            parentControlValue
            defaultValue={specialConditions}
            onInputValueChange={({ value }: { value: string }) => {
              if (value && specialConditions !== value) {
                setIsFormDirty(true)
              }
              setSpecialConditions(value)
            }}
            rows={6}
            maxLength={925}
            showCharCount
            noOptionalLabel
            stretch
          />
          <AgxCheckbox
            id="chkDisplayOnPdf"
            subLabel="Display itemised pricing on PDF"
            naked
            defaultValue={marketingPackage?.displayItemisedPricingOnPdf}
            onValueChanged={({ value }: { value: boolean }) => {
              if (
                marketingPackage?.displayItemisedPricingOnPdf &&
                marketingPackage?.displayItemisedPricingOnPdf !== value
              ) {
                setIsFormDirty(true)
              }
              setIsItemisedPricing(value)
            }}
          />
        </AgxColumn>
      </AgxRow>
      <AgxRow veryLargeGap>
        <AgxColumn fill mediumGap extraClasses="sectionStyle">
          <AgxRow spaceBetween extraClasses="borderBottomContainer">
            <AgxBodyText medium>Standard inclusions</AgxBodyText>
            <AgxButton
              text="Edit Inclusions"
              medium
              hollow
              onClick={() => editInclusions(InclusionsType.STANDARD)}
            />
          </AgxRow>
          {standardItems.length === 0 ? (
            <AgxRow>
              <AgxBodyText medium>
                These will be ticked by default but can be removed by the agent
                when they modify the package.
              </AgxBodyText>
            </AgxRow>
          ) : null}
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleOnDragEndStandard}
          >
            <SortableContext
              items={standardItems
                .map((item) => item.id)
                .filter((id): id is string => id !== undefined)}
              strategy={verticalListSortingStrategy}
            >
              {standardItems &&
                standardItems.map((item, index) =>
                  item.productName === REA_LISTING_LABEL ||
                  item.productName === DOMAIN_LISTING_LABEL
                    ? renderItem(item, index, false)
                    : renderItem(item, index, true)
                )}
            </SortableContext>
          </DndContext>
        </AgxColumn>
        <AgxColumn mediumGap fill extraClasses="sectionStyle">
          <AgxRow spaceBetween extraClasses="borderBottomContainer">
            <AgxBodyText medium>Optional Add-ons</AgxBodyText>
            <AgxButton
              text="Edit Inclusions"
              medium
              hollow
              onClick={() => editInclusions(InclusionsType.ADDONS)}
            />
          </AgxRow>
          {addons.length === 0 ? (
            <AgxRow>
              <AgxBodyText medium>
                These will be displayed unchecked by default to the agent in a
                separate section for upselling.
              </AgxBodyText>
            </AgxRow>
          ) : null}
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleOnDragEndAddons}
          >
            <SortableContext
              items={addons
                .map((item) => item.id)
                .filter((id): id is string => id !== undefined)}
              strategy={verticalListSortingStrategy}
            >
              {addons &&
                addons.map((addon, index) => renderItem(addon, index, true))}
            </SortableContext>
          </DndContext>
        </AgxColumn>
      </AgxRow>
      <DeleteConfirmation
        name={marketingPackage?.name || ''}
        title="Delete marketing package?"
        lineOne="Are you sure you want to delete"
        lineTwo="This cannot be undone."
        primaryActionTitle="Permanently delete package"
        secondaryActionTitle="No, cancel"
        showPopUp={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => onConfirmDelete()}
      />

      <Popup
        title={addInclusionsTitle}
        isOpen={showEditInclusions}
        onClose={() => setShowEditInclusions(false)}
        actionButtons={[
          {
            title: 'Update package',
            isPrimary: true,
            clickHandler: () => setShowEditInclusions(false),
          },
          {
            title: 'Cancel',
            clickHandler: () => setShowEditInclusions(false),
          },
        ]}
        size={{ widthInPX: 640 }}
      >
        <AgxColumn veryLargeGap>
          <AgxRow extraClasses="borderBottomContainer">
            <AgxTextInput
              id="txtSearch"
              label=""
              placeholder="Search"
              noOptionalLabel
              stretch
              onInputValueChange={({ value }: { value: string }) =>
                console.log(value)
              }
              leftIcon={<Images.SearchOutline />}
            />
          </AgxRow>
          {addInclusionsTitle === STANDARD_INCLUSIONS_TITLE
            ? inclusionsList.map((item) => renderEditInclusions(item))
            : addonsList.map((item) => renderEditInclusions(item))}
        </AgxColumn>
      </Popup>
    </AgxColumn>
  )
}

export default EditMarketingPackage
