import { use, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ContractDetailModel } from '@urbanx/agx-ui-components'
import { NotificationContext } from 'contexts'
import { GetContractById } from 'services'
import { CampaignUpdatedEvent, NotificationType } from 'types/notification'
import { useAzureAuth } from './useAzureAuth'

const useSelectedContract = (): {
  contract?: ContractDetailModel
  isLoading: boolean
  isFetched: boolean
} => {
  const { campaignId, contractId } = useParams()
  const queryClient = useQueryClient()
  const [, getAuthToken] = useAzureAuth()
  const notifications = use(NotificationContext)
  const contractQueryKey = ['contract-details', contractId]

  const campaignUpdated = (event: CampaignUpdatedEvent) => {
    if (event.campaignId === campaignId) {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: contractQueryKey }),
      ])
    }
  }

  useEffect(() => {
    notifications?.registerEvent(
      NotificationType.CampaignUpdatedEvent,
      campaignUpdated
    )

    return () => {
      notifications?.unregisterEvent(
        NotificationType.CampaignUpdatedEvent,
        campaignUpdated
      )
    }
  }, [])

  const {
    data: contract,
    isLoading,
    isFetched,
  } = useQuery<ContractDetailModel | undefined>({
    queryKey: contractQueryKey,
    queryFn: (ctx) => GetContractById(ctx.queryKey[1] as string, getAuthToken),
    enabled: !!contractId,
  })

  return {
    contract,
    isLoading,
    isFetched,
  }
}

export default useSelectedContract
