import {
  DisplayElement,
  DocumentTypes,
  UploadedDocument,
} from '@urbanx/agx-ui-components'

export interface EditablePdfUploadConfig extends DisplayElement {
  buttonGroups: EditablePdfButtonGroup[]
  multiple: boolean
  documentType: DocumentTypes
}

export interface EditablePdfButtonGroup {
  buttons: EditablePdfButton[]
}

export interface EditablePdfButton {
  buttonName: string
  pdfFieldName: string
  pdfFieldType: EditablePdfButtonType
}

export enum EditablePdfButtonType {
  Signature = 'Signature',
  SignatureAndDate = 'SignatureAndDate',
  Text = 'Text',
  Checkbox = 'Checkbox',
  Initial = 'Initial',
}

export interface EditablePdfDocumentModel {
  uploadedDocument: UploadedDocument
  documentFields?: string[]
}
