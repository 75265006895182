import { StaffApi } from 'helpers'

class RealestateService {
  authToken: string
  realestateService: StaffApi

  public constructor(authToken: string) {
    this.authToken = authToken
    this.realestateService = new StaffApi('realestate', authToken)
  }
}

export default RealestateService
